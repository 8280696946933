import { View, Text, Image, TouchableOpacity, Linking } from "react-native";
import myCss from "../css/myCss";

const Header = () => {
    const handlePress_spotify = () => {
        Linking.openURL('https://open.spotify.com/artist/5fru1490qqho8UXLmoEJBI');
    };
    const handlePress_appleMusic = () => {
        Linking.openURL('https://music.apple.com/us/artist/emerald-sun/1465224390');
    };
    const handlePress_facebook = () => {
        Linking.openURL('https://www.facebook.com/emeraldsunmetal');
    };
    const handlePress_instagram = () => {
        Linking.openURL('https://www.instagram.com/emerald_sun_official');
    };
    const handlePress_youtube = () => {
        Linking.openURL('https://www.youtube.com/@EmeraldSun');
    };
    const handlePress_twitter = () => {
        Linking.openURL('https://twitter.com/Emerald_Sun_gr');
    };
    
    return (
        <View style={myCss.header}>
            <TouchableOpacity onPress={handlePress_spotify}>
                <Image source={require('../assets/spotify.png')} style={myCss.socialIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={handlePress_appleMusic}>
                <Image source={require('../assets/apple_music.png')} style={myCss.socialIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={handlePress_facebook}>
                <Image source={require('../assets/facebook.png')} style={myCss.socialIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={handlePress_instagram}>
                <Image source={require('../assets/instagram.png')} style={myCss.socialIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={handlePress_youtube}>
                <Image source={require('../assets/youtube.png')} style={myCss.socialIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={handlePress_twitter}>
                <Image source={require('../assets/twitter.png')} style={myCss.socialIcon} />
            </TouchableOpacity>
        </View>
    );
};
  
export default Header; 