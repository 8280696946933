import { View, Text } from 'react-native';
import myCss from '../css/myCss';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Header from './Header';
import Footer from './Footer';

import image1 from '../assets/image1.jpg';
import image2 from '../assets/image2.jpg';
import image3 from '../assets/image3.jpg';
import image4 from '../assets/image4.jpg';
import image5 from '../assets/image5.jpg';
import image6 from '../assets/image6.jpg';
import image7 from '../assets/image7.jpg';
import image8 from '../assets/image8.jpg';
import image9 from '../assets/image9.jpg';
import image10 from '../assets/image10.jpg';
import image11 from '../assets/image11.jpg';
import image12 from '../assets/image12.jpg';
import image13 from '../assets/image13.jpg';
import image14 from '../assets/image14.jpg';
import image15 from '../assets/image15.jpg';
import image16 from '../assets/image16.jpg';
import image17 from '../assets/image17.jpg';
import image18 from '../assets/image18.jpg';
import image19 from '../assets/image19.jpg';
import image20 from '../assets/image20.jpg';
import image21 from '../assets/image21.jpg';
import image22 from '../assets/image22.jpg';
import image23 from '../assets/image23.jpg';
import image24 from '../assets/image24.jpg';
import image25 from '../assets/image25.jpg';
import image26 from '../assets/image26.jpg';
import image27 from '../assets/image27.jpg';
import image28 from '../assets/image28.jpg';


const GalleryScreen = () => {

  const images = [
    { original: image1, thumbnail: image1, description: '' },
    { original: image2, thumbnail: image2, description: '' },
    { original: image3, thumbnail: image3, description: '' },
    { original: image4, thumbnail: image4, description: '' },
    { original: image5, thumbnail: image5, description: '' },
    { original: image6, thumbnail: image6, description: '' },
    { original: image7, thumbnail: image7, description: '' },
    { original: image8, thumbnail: image8, description: '' },
    { original: image9, thumbnail: image9, description: '' },
    { original: image10, thumbnail: image10, description: '' },
    { original: image11, thumbnail: image11, description: '' },
    { original: image12, thumbnail: image12, description: '' },
    { original: image13, thumbnail: image13, description: '' },
    { original: image14, thumbnail: image14, description: '' },
    { original: image15, thumbnail: image15, description: '' },
    { original: image16, thumbnail: image16, description: '' },
    { original: image17, thumbnail: image17, description: '' },
    { original: image18, thumbnail: image18, description: '' },
    { original: image19, thumbnail: image19, description: '' },
    { original: image20, thumbnail: image20, description: '' },
    { original: image21, thumbnail: image21, description: '' },
    { original: image22, thumbnail: image22, description: '' },
    { original: image23, thumbnail: image23, description: '' },
    { original: image24, thumbnail: image24, description: '' },
    { original: image25, thumbnail: image25, description: '' },
    { original: image26, thumbnail: image26, description: '' },
    { original: image27, thumbnail: image27, description: '' },
    { original: image28, thumbnail: image28, description: '' },
  ];  

  return (
    <View style={myCss.containerGallery}>
      <Header/>
      <ImageGallery items={images} showPlayButton={true} />
      <View style={{ alignItems: 'center' }}>
        <Footer/>
      </View>
    </View>
  );
};

export default GalleryScreen;