import { View, Text } from 'react-native';
import Catalog from "react-catalog-view";
import myCss from '../css/myCss';
import Header from './Header';
import Footer from './Footer';



const ShopScreen = () => {

  let products = 
       [
         {
            id: 1,
            title: "Emerald Sun Regeneration T-shirt",
            description: "",
            price: "20",
            discounted: "15",
            currency: "$",
            image: require('../assets/eshop/Emerald Sun Regeneration T-shirt.jpg'),
         }
      ];

      const CONTENT_KEYS = 
      {             
         imgKey: "image",
         cardTitleKey: "title",
         cardDescriptionKey: "description",
         priceKey: "price",
         discountedPriceKey: "discounted",
         priceCurrencyKey: "currency",
         discountCurrencyKey: "currency"
      };

  return (
    <View style={myCss.container}>
      <Header/>
      <Catalog
          data = {products}		
          // Array of JSON Objects (required)
          contentKeys={CONTENT_KEYS}  
          // JSON Object defining the keys that will be 
          // used from the data array, keys should match. (required)
          skeleton={0}
          // Any non zero number will override default cards
          // and will show that many skeleton cards.           
          cardSize="sm"
          // Card sizes, sm, md and lg for small, medium  and large
          btnOneText="View"
          // Enter text for action button one 
          // or pass empty string to hide.  
          btnTwoText="Purchase Now"
          // Enter text for action button two 
          // or pass empty string to hide.
          btnOneHandler={(args, event, objectData)=>{
          // 'objectData' returns object data from 'data' prop
          // any arguments passed will be before 'event' 
          // and 'objectData'
          }}
          btnTwoHandler={(args, event, row)=>{
          // 'objectData' returns object data from 'data' prop
          // any arguments passed will be before 'event' 
          // and 'objectData'
          }}
          imageClickHandler={(args, event, row)=>{
          // 'objectData' returns object data from 'data' prop
          // any arguments passed will be before 'event' 
          // and 'objectData'
          }}
          cardControls={ dataObj => {
              return(
                <div>
                    <input className='my-custom-input' placeholder='custom-input' />
                    <button className='my-custom-button' type='submit'> OK </button> 
                </div>
              )
          }}
          // Pass a function which returns JSX to be rendered inside card
          // This function will have 'dataObj' containing JSON of
          // the item each card represents
      />
      <Footer/>
    </View>
  );
};

export default ShopScreen;