import { CardContent } from '@material-ui/core';
import GenericConstManager from '../managers/GenericConstManager';
import { Dimensions, StyleSheet } from 'react-native';

const screenDimensions = Dimensions.get('window');
const backgroundImage = require('../assets/background3.jpg');

const myCss = StyleSheet.create({
  bandMemberCard: {
    width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH + 4,
    backgroundColor: 'black',
    margin: 10,
    borderColor: 'white',
    borderWidth: 2
  },
  bandMemberCardTitle: {
    color: 'white'
  },
  cardContent: {
    marginTop: 20
  },
  container: {
    //flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    //justifyContent: 'flex-start',
    backgroundImage: `url(${backgroundImage})`
  },
  containerGallery: {
    backgroundImage: `url(${backgroundImage})`
  },
  divider: {
    width: screenDimensions.width - 40,
    maxWidth: GenericConstManager.getInstance().WEB_MAX_WIDTH,
    backgroundColor: 'lightgray'
  },
  footer: {
    width: screenDimensions.width - 40,
    maxWidth: GenericConstManager.getInstance().WEB_MAX_WIDTH,
    //backgroundColor: 'blue',
    alignItems: 'center',
    marginTop: 10
  },
  footerText: {
    color: 'lightgray',
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5
  },
  header: {
    width: screenDimensions.width-20,
    marginTop: 5,
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  mainApp: {
    width: screenDimensions.width - 40,
    maxWidth: GenericConstManager.getInstance().WEB_MAX_WIDTH,
    alignItems: 'center',
    //height: screenDimensions.height - 80,
    //backgroundColor: 'black'
  },
  navigationBarElement: {
    color: 'white', 
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10
  },
  socialIcon: {
    width: 30, 
    height: 30,
    margin: 5
  },
  songLyricsAccordion: {
    width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH - 32,
    backgroundColor: 'white'
  },
  songLyricsAccordionSummary: {
    backgroundColor: '#E5E5E5',
    color: 'black'
  },
  songLyricsText: {
    fontSize: 12
  },
  text: {
    color: 'white',
    textAlign: 'justify',
    marginTop: 20
  },
  textBoldRed: {
    fontWeight: 'bold',
    color: 'red'
  },
  textBoldWhite: {
    fontWeight: 'bold',
    color: 'white'
  },
  video: {
    margin: 10
  }
});

export default myCss;