class GenericConstManager {
    public readonly HOME: string = "HOME";
    public readonly NEWS: string = "NEWS";
    public readonly GALLERY: string = "GALLERY";
    public readonly VIDEOS: string = "VIDEOS";
    public readonly DISCOGRAPHY: string = "DISCOGRAPHY";
    public readonly BAND: string = "BAND";
    public readonly SHOP: string = "SHOP";
    
    public readonly LOGO_WIDTH = 1730;
    public readonly LOGO_HEIGHT = 466;

    public readonly WEB_MAX_WIDTH = 1200;

    public readonly BAND_MEMBER_CARD_WIDTH = 600;

    private static instance: GenericConstManager;

    private constructor() { 
    }

    public static getInstance(): GenericConstManager {
        if (!GenericConstManager.instance) {
            GenericConstManager.instance = new GenericConstManager();
        }

        return GenericConstManager.instance;
    }
}

export default GenericConstManager;