import { View, Text } from 'react-native';
import myCss from '../css/myCss';
import Header from './Header';
import Footer from './Footer';


const NewsScreen = () => {
  return (
    <View style={myCss.container}>
      <Header/>
      <Text style={myCss.text}>Welcome to News Screen!</Text>
      <Footer/>
    </View>
  );
};

export default NewsScreen;