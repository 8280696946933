import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image, TouchableOpacity, Linking } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import GenericConstManager from './managers/GenericConstManager';
import myCss from './css/myCss'; //
import HomeScreen from './screens/HomeScreen';
import NewsScreen from './screens/NewsScreen';
import GalleryScreen from './screens/GalleryScreen';
import VideosScreen from './screens/VideosScreen';
import DiscographyScreen from './screens/DiscographyScreen';
import BandScreen from './screens/BandScreen';
import ShopScreen from './screens/ShopScreen';
import { useState } from 'react';

const Stack = createNativeStackNavigator();

export default function App() {

  const linking = {
    prefixes: ['emeraldsunwebsite://'],
    config: {
      screens: {
        Home: 'home',
        News: 'news',
        Gallery: 'gallery',        
        Videos: 'videos',
        Discography: 'discography',
        Band: 'band',
        Shop: 'shop'
      },
    },
  };

  const [curPage, setCurPage] = useState(GenericConstManager.getInstance().HOME);

  const navigationOptions: NativeStackNavigationOptions = ({ navigation }) => ({
    headerStyle: {
      backgroundColor: 'black', // Change background color here
    },
    headerTitle: () => (
      <Image
        source={require('./assets/logo.png')}
        style={{ width: 50*(GenericConstManager.getInstance().LOGO_WIDTH/GenericConstManager.getInstance().LOGO_HEIGHT), height: 50, marginLeft: 10 }} 
        resizeMode="contain"
      />
    ),
    headerTitleAlign: 'left',
    headerRight: () => (
      <View>
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => {
              setCurPage(GenericConstManager.getInstance().HOME);
              navigation.reset({
                index: 1,
                routes: [{ name: 'Home' }]
              });
            }}
          >
            <Text style={[myCss.navigationBarElement, {fontWeight: curPage==GenericConstManager.getInstance().HOME ? "bold" : "normal", color: curPage==GenericConstManager.getInstance().HOME ? 'red' : 'white' }]}>Home</Text>
          </TouchableOpacity>
          
          <TouchableOpacity
            onPress={() => {
              setCurPage(GenericConstManager.getInstance().NEWS);
              navigation.reset({
                index: 1,
                routes: [{ name: 'News' }]
              });
            }}
          >
            <Text style={[myCss.navigationBarElement, {fontWeight: curPage==GenericConstManager.getInstance().NEWS ? "bold" : "normal", color: curPage==GenericConstManager.getInstance().NEWS ? 'red' : 'white' }]}>News</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setCurPage(GenericConstManager.getInstance().GALLERY);
              navigation.reset({
                index: 1,
                routes: [{ name: 'Gallery' }]
              });
            }}
          >
            <Text style={[myCss.navigationBarElement, {fontWeight: curPage==GenericConstManager.getInstance().GALLERY ? "bold" : "normal", color: curPage==GenericConstManager.getInstance().GALLERY ? 'red' : 'white' }]}>Gallery</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setCurPage(GenericConstManager.getInstance().VIDEOS);
              navigation.reset({
                index: 1,
                routes: [{ name: 'Videos' }]
              });
            }}
          >
            <Text style={[myCss.navigationBarElement, {fontWeight: curPage==GenericConstManager.getInstance().VIDEOS ? "bold" : "normal", color: curPage==GenericConstManager.getInstance().VIDEOS ? 'red' : 'white' }]}>Videos</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setCurPage(GenericConstManager.getInstance().DISCOGRAPHY);
              navigation.reset({
                index: 1,
                routes: [{ name: 'Discography' }]
              });
            }}
          >
            <Text style={[myCss.navigationBarElement, {fontWeight: curPage==GenericConstManager.getInstance().DISCOGRAPHY ? "bold" : "normal", color: curPage==GenericConstManager.getInstance().DISCOGRAPHY ? 'red' : 'white' }]}>Discography</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setCurPage(GenericConstManager.getInstance().BAND);
              navigation.reset({
                index: 1,
                routes: [{ name: 'Band' }]
              });
            }}
          >
            <Text style={[myCss.navigationBarElement, {fontWeight: curPage==GenericConstManager.getInstance().BAND ? "bold" : "normal", color: curPage==GenericConstManager.getInstance().BAND ? 'red' : 'white' }]}>The Band</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              /*setCurPage(GenericConstManager.getInstance().SHOP);
              navigation.reset({
                index: 1,
                routes: [{ name: 'Shop' }]
              });*/
              Linking.openURL('https://emeraldsunshop.netlify.app/');
            }}
          >
            <Text style={[myCss.navigationBarElement, {fontWeight: curPage==GenericConstManager.getInstance().SHOP ? "bold" : "normal", color: curPage==GenericConstManager.getInstance().SHOP ? 'red' : 'white' }]}>Shop</Text>
          </TouchableOpacity>
        </View>
      </View>
    ),
  });
  
  return (
    <NavigationContainer>
      <Stack.Navigator 
          initialRouteName="Home" 
          linking={linking}        
          screenOptions={navigationOptions}
      >
        <Stack.Screen name="Home" component={HomeScreen} options={({ route }) => ({ title: 'Emerald Sun - Official Website' })} />
        <Stack.Screen name="News" component={NewsScreen} options={({ route }) => ({ title: 'Emerald Sun - News' })} />
        <Stack.Screen name="Gallery" component={GalleryScreen} options={({ route }) => ({ title: 'Emerald Sun - Gallery' })} />
        <Stack.Screen name="Videos" component={VideosScreen} options={({ route }) => ({ title: 'Emerald Sun - Videos' })} />
        <Stack.Screen name="Discography" component={DiscographyScreen} options={({ route }) => ({ title: 'Emerald Sun - Discography' })} />
        <Stack.Screen name="Band" component={BandScreen} options={({ route }) => ({ title: 'Emerald Sun - The Band' })} />
        <Stack.Screen name="Shop" component={ShopScreen} options={({ route }) => ({ title: 'Emerald Sun - Shop' })} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}