import { useState } from 'react';
import { View, Text } from 'react-native';
import myCss from '../css/myCss';
import Header from './Header';
import Footer from './Footer';
import GenericConstManager from '../managers/GenericConstManager';
import { Card } from 'react-native-paper';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormatAlignCenter } from '@material-ui/icons';




const DiscographyScreen = () => {
  const [selectedSong, setSelectedSong] = useState(null);

  const songs = [
    { title: 'Song 1', lyrics: 'Lyrics for Song 1...' },
    { title: 'Song 2', lyrics: 'Lyrics for Song 2...' },
    { title: 'Song 3', lyrics: 'Lyrics for Song 3...' },
  ];

  const handleSelect = (song) => {
    setSelectedSong(selectedSong === song ? null : song);
  };

  return (
    <View style={myCss.container}>
      <Header/>
      <View style={myCss.mainApp}>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="KINGDOM OF GODS (2021)" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*678)/678 }} source={require('../assets/kingdom_of_gods.png')} />
          <Card.Content style={myCss.cardContent}>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>1. Book of Genesis (04:12)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    The book of genesis opens{'\n'}
                    The verse of death{'\n'}
                    Has been spoken{'\n'}
                    The signs cannot be ignored{'\n'}
                    By any mortal{'\n'}
                    {'\n'}
                    Seven kingdoms{'\n'}
                    Claim the crown{'\n'}
                    The dead are cursing{'\n'}
                    Underground{'\n'}
                    And we are walking{'\n'}
                    Through the gates of hell{'\n'}
                    {'\n'}
                    The skies dressed{'\n'}
                    In a mounting threat{'\n'}
                    Darkness falling overhead{'\n'}
                    The shadow forces{'\n'}
                    Start the end of time{'\n'} 
                    {'\n'}
                    Noone cares{'\n'}
                    {'\n'}
                    The book of genesis opens{'\n'}
                    The verse of death{'\n'}
                    Has been spoken{'\n'}
                    The signs cannot be ignored{'\n'}
                    By any mortal{'\n'}
                    {'\n'}
                    The earth drowns{'\n'}
                    In a lake of blood{'\n'}
                    Is that the final word of god?{'\n'}
                    The children he created{'\n'}
                    Lost their way{'\n'}
                    {'\n'}
                    The apocalypse is near{'\n'}
                    Broken voices full of fear{'\n'}
                    Receive the harvest of insanity{'\n'}
                    Noone cares{'\n'}
                    {'\n'}
                    The book of genesis opens{'\n'}
                    The verse of death{'\n'}
                    Has been spoken{'\n'}
                    The signs cannot be ignored{'\n'}
                    By any mortal{'\n'}
                    {'\n'}
                    Demons with an angels face{'\n'}
                    Rapists of the human race{'\n'}
                    Exterminate and hypnotize{'\n'}
                    A kingdom made{'\n'}
                    Through sacrifice
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>2. Heroes on the Rise (05:11)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    An implanted conviction{'\n'}
                    Is a double crucifixion{'\n'}
                    So beware{'\n'}
                    A hidden warfare{'\n'}
                    {'\n'}
                    Torment by existence{'\n'}
                    Led to riot{'\n'}
                    And resistance in the end{'\n'}
                    {'\n'}
                    Sound the bell{'\n'}
                    For their descend{'\n'}
                    Taking back{'\n'}
                    What's out of hand{'\n'}
                    {'\n'}
                    In this hell{'\n'}
                    Beyond the light{'\n'}
                    Leading the other side{'\n'}
                    From the silvery skies{'\n'}
                    Bringing faith and desire{'\n'}
                    Break the ice{'\n'}
                    We are heroes on the rise{'\n'}
                    {'\n'}
                    Signs of pure confusion{'\n'}
                    Created a disillusion{'\n'}
                    In our mind{'\n'}
                    As we were blind{'\n'}
                    {'\n'}
                    Kings without a face{'\n'}
                    Spilling misery{'\n'}
                    And disgrace{'\n'}
                    Over the land{'\n'}
                    {'\n'}
                    Sound the bell{'\n'}
                    For their descend{'\n'}
                    Taking back{'\n'}
                    What's out of hand{'\n'}
                    {'\n'}
                    In this hell{'\n'}
                    Beyond the light{'\n'}
                    Leading the other side{'\n'}
                    From the silvery skies{'\n'}
                    Bringing faith and desire{'\n'}
                    Break the ice{'\n'}
                    We are heroes on the rise{'\n'}
                    {'\n'}
                    If my destiny dares me{'\n'}
                    To die in this battle{'\n'}
                    I'll take the chance{'\n'}
                    Cause a future in{'\n'}
                    Freedom and peace{'\n'}
                    Now is of the essence{'\n'}
                    Killed the demons{'\n'}
                    In my soul{'\n'}
                    That stood so strong{'\n'}
                    Just to finally understand{'\n'}
                    Where I belong{'\n'}
                    {'\n'}
                    Sound the bell{'\n'}
                    For their descend{'\n'}
                    Taking back{'\n'}
                    What's out of hand{'\n'}
                    {'\n'}
                    In this hell{'\n'}
                    Beyond the light{'\n'}
                    Leading the other side{'\n'}
                    From the silvery skies{'\n'}
                    Bringing faith and desire{'\n'}
                    Break the ice{'\n'}
                    We are heroes on the rise
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>3. Hellbound (03:50)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Princess of pain{'\n'}
                    The devil's voodoo queen{'\n'}
                    She is the perfect love machine{'\n'}
                    Her eyes ablazed{'\n'}
                    Inflamed with death's desire{'\n'}
                    An animal that can't be tamed{'\n'}
                    {'\n'}
                    Vampire fangs behind her lips{'\n'}
                    A pleasure's pain and{'\n'}
                    Her heat runs right{'\n'}
                    Through your veins{'\n'}
                    {'\n'}
                    Hellbound....hellhound{'\n'}
                    The will is strong{'\n'}
                    The flesh too weak{'\n'}
                    Hellbound....hellhound{'\n'}
                    This lady is unique{'\n'}
                    {'\n'}
                    Lips on your neck{'\n'}
                    The smoothest bite you feel{'\n'}
                    At her altar of sin you kneel{'\n'}
                    She drinks your blood{'\n'}
                    You're riding hade's rails{'\n'}
                    You won't resist{'\n'}
                    Cause lust prevails{'\n'}
                    {'\n'}
                    She nails your ass{'\n'}
                    On to the sheets{'\n'}
                    And when she's done{'\n'}
                    She leaves you dying in the sun{'\n'}
                    {'\n'}
                    Hellbound....hellhound{'\n'}
                    The will is strong{'\n'}
                    The flesh too weak{'\n'}
                    Hellbound....hellhound{'\n'}
                    This lady is unique{'\n'}
                    Hellbound....hellhound{'\n'}
                    Dinner's at eight but{'\n'}
                    She can't wait{'\n'}
                    Hellbound....hellhound{'\n'}
                    Obsessed by sainted hate{'\n'}
                    {'\n'}
                    Hellbound....hellhound{'\n'}
                    The will is strong{'\n'}
                    The flesh too weak{'\n'}
                    Hellbound....hellhound{'\n'}
                    This lady is unique{'\n'}
                    Hellbound....hellhound{'\n'}
                    Dinner's at eight but{'\n'}
                    She can't wait{'\n'}
                    Hellbound....hellhound{'\n'}
                    Obsessed by sainted hate{'\n'}
                    {'\n'}
                    Hellbound....hellhound{'\n'}
                    The will is strong{'\n'}
                    The flesh too weak{'\n'}
                    Hellbound....hellhound{'\n'}
                    This lady is unique{'\n'}
                    Hellbound....hellhound{'\n'}
                    An ecstasy you've never been{'\n'}
                    Hellbound....hellhound{'\n'}
                    Lucifer's hand of sin
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>4. Legions of Doom (04:18)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    See the northern lights{'\n'}
                    Above the fields of battle{'\n'}
                    Gleaming in the sky{'\n'}
                    To defy the allseeing eye{'\n'}
                    {'\n'}
                    Human genocide{'\n'}
                    Waves of decay still striking{'\n'}
                    Raped the land{'\n'}
                    Down to the core{'\n'}
                    In a deceptive war{'\n'}
                    {'\n'}
                    The plan is on for centuries{'\n'}
                    Now it's time to change{'\n'}
                    The tide of this dance{'\n'}
                    And turn to offence{'\n'}
                    {'\n'}
                    Legions of doom{'\n'}
                    Face the final fight{'\n'}
                    Marching on{'\n'}
                    Our glory is in sight{'\n'}
                    Legions of doom{'\n'}
                    No one shall remain{'\n'}
                    Back to freedom{'\n'}
                    Let us rise again{'\n'}
                    {'\n'}
                    Winds are whispering{'\n'}
                    Creating sounds of thunder{'\n'}
                    We're the ones{'\n'}
                    To break the spell{'\n'}
                    In this hells carousel{'\n'}
                    {'\n'}
                    Recall where you come from{'\n'}
                    You were flame and fire{'\n'}
                    You were blood inside that vein{'\n'}
                    Now we will regain{'\n'}
                    {'\n'}
                    The plan is on for centuries{'\n'}
                    Now it's time to{'\n'}
                    Change the tide of this dance{'\n'}
                    And turn to offence{'\n'}
                    {'\n'}
                    Legions of doom{'\n'}
                    Face the final fight{'\n'}
                    Marching on{'\n'}
                    Our glory is in sight{'\n'}
                    Legions of doom{'\n'}
                    No one shall remain{'\n'}
                    Back to freedom{'\n'}
                    Let us rise again{'\n'}
                    {'\n'}
                    Over walls of pain{'\n'}
                    Walked through{'\n'}
                    Snow and rain{'\n'}
                    Death's bell{'\n'}
                    Takes its darkest toll{'\n'}
                    We're in control{'\n'}
                    {'\n'}
                    Legions of doom{'\n'}
                    Face the final fight{'\n'}
                    Marching on{'\n'}
                    Our glory is in sight{'\n'}
                    Legions of doom{'\n'}
                    No one shall remain{'\n'}
                    Back to freedom{'\n'}
                    Let us rise again
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>5. Gaia (The End of Innocence) (04:17)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Drowned in sin and greediness{'\n'}
                    We suffer from delusion{'\n'}
                    Blinded by our profit{'\n'}
                    We live in confusion{'\n'}
                    {'\n'}
                    Disrespected mother earth{'\n'}
                    Gave everything a price{'\n'}
                    Instead of human values{'\n'}
                    We chose sacrifice{'\n'}
                    {'\n'}
                    Gaia is her name{'\n'}
                    Revenge what she became{'\n'}
                    A cleanse to show us{'\n'}
                    Who's to blame{'\n'}
                    {'\n'}
                    Storms to end the innocence{'\n'}
                    In days of decadence{'\n'}
                    Will we ever break this spell{'\n'}
                    Or raise the flag of hell{'\n'}
                    {'\n'}
                    At the point of no return{'\n'}
                    One step before extinction{'\n'}
                    We ignore reality{'\n'}
                    In worlds of fiction{'\n'}
                    {'\n'}
                    Earth endured us for too long{'\n'}
                    Don't wait for her reaction{'\n'}
                    She will show us finally{'\n'}
                    Our own reflection{'\n'}
                    {'\n'}
                    Gaia is her name{'\n'}
                    Revenge what she became{'\n'}
                    A cleanse to show us{'\n'}
                    Who's to blame{'\n'}
                    {'\n'}
                    Storms to end the innocence{'\n'}
                    In days of decadence{'\n'}
                    Will we ever break this spell{'\n'}
                    Or raise the flag of hell{'\n'}
                    {'\n'}
                    Gaia is her name{'\n'}
                    Revenge what she became{'\n'}
                    A cleanse to show us{'\n'}
                    Who's to blame{'\n'}
                    {'\n'}
                    Storms to end the innocence{'\n'}
                    In days of decadence{'\n'}
                    Will we ever break this spell{'\n'}
                    Or raise the flag of hell
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>6. Kingdom of Gods (05:44)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    As children of the titans{'\n'}
                    They were born{'\n'}
                    With the purpose to{'\n'}
                    Protect and reign{'\n'}
                    To serve all warriors{'\n'}
                    On Hellenic ground{'\n'}
                    Giving them strength{'\n'}
                    And ease their pain{'\n'}
                    {'\n'}
                    Zeus{'\n'}
                    Poseidon{'\n'}
                    Hera{'\n'}
                    And Hermes{'\n'}
                    Pluton{'\n'}
                    {'\n'}
                    Called by ritual{'\n'}
                    And sacrifice{'\n'}
                    To empower us{'\n'}
                    For war and fate{'\n'}
                    And as lightning strikes{'\n'}
                    Across the skies{'\n'}
                    We know it's time{'\n'}
                    To draw the blade{'\n'}
                    {'\n'}
                    Hephaestus{'\n'}
                    Athena{'\n'}
                    Demeter{'\n'}
                    Ares{'\n'}
                    Apollo{'\n'}
                    Aphrodite{'\n'}
                    Artemis{'\n'}
                    {'\n'}
                    In the kingdom of gods{'\n'}
                    From the crown of Olympus{'\n'}
                    In majesty they{'\n'}
                    Rule the holy land{'\n'}
                    In the kingdom of gods{'\n'}
                    From the crown of Olympus{'\n'}
                    The universal power{'\n'}
                    Is at hand{'\n'}
                    {'\n'}
                    Every enemy's been{'\n'}
                    Terrified{'\n'}
                    From the wrath of this{'\n'}
                    Twelve overlords{'\n'}
                    As Olympians{'\n'}
                    Acclaimed worldwide{'\n'}
                    To glory with pride{'\n'}
                    They lead their hordes{'\n'}
                    {'\n'}
                    Zeus{'\n'}
                    Poseidon{'\n'}
                    Hera{'\n'}
                    And Hermes{'\n'}
                    Pluton{'\n'}
                    Hephaestus{'\n'}
                    Athena{'\n'}
                    Demeter{'\n'}
                    Ares{'\n'}
                    Apollo{'\n'}
                    Aphrodite{'\n'}
                    Artemis{'\n'}
                    {'\n'}
                    In the kingdom of gods{'\n'}
                    From the crown{'\n'}
                    Of Olympus{'\n'}
                    In majesty they{'\n'}
                    Rule the holy land{'\n'}
                    In the kingdom of gods{'\n'}
                    From the crown{'\n'}
                    Of Olympus{'\n'}
                    The universal power{'\n'}
                    Is at hand
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>7. Raise Hell (04:00)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Bloodbrothers in fate{'\n'}
                    Don't fit in conception{'\n'}
                    Living our lives our own way{'\n'}
                    Adopting no shape{'\n'}
                    Just go with our passion{'\n'}
                    Born to lead not to obey{'\n'}
                    {'\n'}
                    Connected by blood{'\n'}
                    United in energy{'\n'}
                    A circle of brothers{'\n'}
                    Beyond rivalry{'\n'}
                    {'\n'}
                    Raise hell{'\n'}
                    Here to raise hell{'\n'}
                    Unleash a storm of power{'\n'}
                    Raise hell{'\n'}
                    Here to raise hell{'\n'}
                    Causing waves that devour{'\n'}
                    Raise hell{'\n'}
                    {'\n'}
                    Out of control{'\n'}
                    Not easy to handle{'\n'}
                    Always a thorn in their eye{'\n'}
                    We are what we are{'\n'}
                    We don't play it gentle{'\n'}
                    Insurgents until we die{'\n'}
                    {'\n'}
                    With steel in our veins{'\n'}
                    We follow{'\n'}
                    Our heart's command{'\n'}
                    And spreading the sound{'\n'}
                    All over the land{'\n'}
                    {'\n'}
                    Raise hell{'\n'}
                    Here to raise hell{'\n'}
                    Unleash a storm of power{'\n'}
                    Raise hell{'\n'}
                    Here to raise hell{'\n'}
                    Causing waves that devour{'\n'}
                    Raise hell{'\n'}
                    {'\n'}
                    With steel in our veins{'\n'}
                    We follow{'\n'}
                    Our heart's command{'\n'}
                    And spreading the sound{'\n'}
                    All over the land{'\n'}
                    {'\n'}
                    Raise hell{'\n'}
                    Here to raise hell{'\n'}
                    Unleash a storm of power{'\n'}
                    Raise hell{'\n'}
                    Here to raise hell{'\n'}
                    Causing waves that devour{'\n'}
                    Raise hell
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>8. The Hunter (04:45)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Moving through the haze{'\n'}
                    Lying in wait in this maze{'\n'}
                    In vast lands{'\n'}
                    And forests I seek{'\n'}
                    For the beast to{'\n'}
                    Show his face{'\n'}
                    Or at least to find a trace{'\n'}
                    To keep pace...{'\n'}
                    {'\n'}
                    Full moon and{'\n'}
                    The dread is on the prowl{'\n'}
                    {'\n'}
                    Claws sharp as steel{'\n'}
                    Rip the skin{'\n'}
                    Doesn't feel any mercy or sin{'\n'}
                    Spreading death{'\n'}
                    Across the land{'\n'}
                    Now his fate is in my hands{'\n'}
                    I will hunt him down{'\n'}
                    I will hunt him down{'\n'}
                    Take his life and{'\n'}
                    Bring this to an end{'\n'}
                    {'\n'}
                    Fatal silence around{'\n'}
                    Lurking danger{'\n'}
                    With no sound{'\n'}
                    An underworld{'\n'}
                    Freak on the loose{'\n'}
                    He is half beast and half man{'\n'}
                    Time to end what he began{'\n'}
                    And I can...{'\n'}
                    {'\n'}
                    Full moon and{'\n'}
                    The dread is on the prowl{'\n'}
                    {'\n'}
                    Claws sharp as steel{'\n'}
                    Rip the skin{'\n'}
                    Doesn't feel any mercy or sin{'\n'}
                    Spreading death{'\n'}
                    Across the land{'\n'}
                    Now his fate is in my hands{'\n'}
                    I will hunt him down{'\n'}
                    I will hunt him down{'\n'}
                    Take his life and{'\n'}
                    Bring this to an end{'\n'}
                    {'\n'}
                    Full moon and{'\n'}
                    The dread is on the prowl{'\n'}
                    {'\n'}
                    Claws sharp as steel{'\n'}
                    Rip the skin{'\n'}
                    Doesn't feel any mercy or sin{'\n'}
                    Spreading death{'\n'}
                    Across the land{'\n'}
                    Now his fate is in my hands{'\n'}
                    I will hunt him down{'\n'}
                    I will hunt him down{'\n'}
                    Take his life and{'\n'}
                    Bring this to an end
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>9. We Will Die on Our Feet (03:54)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    False kings kept the power{'\n'}
                    Oppressing us all{'\n'}
                    In this final hour{'\n'}
                    We bring their downfall{'\n'}
                    {'\n'}
                    On our knees{'\n'}
                    We won't stay{'\n'}
                    Your dominion is{'\n'}
                    Beginning to decay{'\n'}
                    {'\n'}
                    We will die on our feet{'\n'}
                    We are stronger than ever{'\n'}
                    We won't live on our knees{'\n'}
                    We will never surrender{'\n'}
                    We will die on our feet{'\n'}
                    {'\n'}
                    Blaze of determination{'\n'}
                    Is in our eyes{'\n'}
                    This is where your empire dies{'\n'}
                    {'\n'}
                    Prepared the tombs{'\n'}
                    For masters to die as slaves{'\n'}
                    The holes you digged{'\n'}
                    Became your graves{'\n'}
                    {'\n'}
                    On our knees{'\n'}
                    We won't stay{'\n'}
                    Your dominion is{'\n'}
                    Beginning to decay{'\n'}
                    {'\n'}
                    We will die on our feet{'\n'}
                    We are stronger than ever{'\n'}
                    We won't live on our knees{'\n'}
                    We will never surrender{'\n'}
                    We will die on our feet{'\n'}
                    {'\n'}
                    On our knees{'\n'}
                    We won't stay{'\n'}
                    Your dominion is{'\n'}
                    Beginning to decay{'\n'}
                    {'\n'}
                    We will die on our feet{'\n'}
                    We are stronger than ever{'\n'}
                    We won't live on our knees{'\n'}
                    We will never surrender{'\n'}
                    We will die on our feet
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>10. Where Warriors Belong (04:57)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    And finally here I stand{'\n'}
                    Embraced by my last sunset{'\n'}
                    The gods were{'\n'}
                    Always by my side{'\n'}
                    Today we reunite{'\n'}
                    {'\n'}
                    The wounds from steel are deep{'\n'}
                    But sense of pain is absent{'\n'}
                    The only thing I feel is pride{'\n'}
                    For being on this ride{'\n'}
                    {'\n'}
                    Before the dawn{'\n'}
                    I close my eyes{'\n'}
                    I'm marked by scars of war{'\n'}
                    Before the dawn{'\n'}
                    My soul will rise{'\n'}
                    To reach the final shore{'\n'}
                    {'\n'}
                    Before the dawn{'\n'}
                    I close my eyes{'\n'}
                    In lifetime I stood strong{'\n'}
                    Before the dawn{'\n'}
                    My soul will rise{'\n'}
                    Where warriors belong{'\n'}
                    {'\n'}
                    I fought for my beliefs{'\n'}
                    My enemies defeated{'\n'}
                    Yet in this moment that I die{'\n'}
                    You'll hear my battle cry{'\n'}
                    {'\n'}
                    Before the dawn{'\n'}
                    I close my eyes{'\n'}
                    I'm marked by scars of war{'\n'}
                    Before the dawn{'\n'}
                    My soul will rise{'\n'}
                    To reach the final shore{'\n'}
                    {'\n'}
                    Before the dawn{'\n'}
                    I close my eyes{'\n'}
                    In lifetime I stood strong{'\n'}
                    Before the dawn{'\n'}
                    My soul will rise{'\n'}
                    Where warriors belong{'\n'}
                    {'\n'}
                    Before the dawn{'\n'}
                    I close my eyes{'\n'}
                    I'm marked by scars of war{'\n'}
                    Before the dawn{'\n'}
                    My soul will rise{'\n'}
                    To reach the final shore{'\n'}
                    {'\n'}
                    Before the dawn{'\n'}
                    I close my eyes{'\n'}
                    In lifetime I stood strong{'\n'}
                    Before the dawn{'\n'}
                    My soul will rise{'\n'}
                    Where warriors belong
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card.Content>
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="UNDER THE CURSE OF SILENCE (2018)" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*1200)/1200 }} source={require('../assets/under_the_curse_of_silence.jpg')} />
          <Card.Content style={myCss.cardContent}>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>1. Kill Or Be Killed (4:32)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Under the curse of silence{'\n'}
                    Under the spell of violence{'\n'}
                    Under the ruins of our dreams{'\n'}
                    Under insatiable regimes{'\n'}
                    {'\n'}
                    It's all said and done{'\n'}
                    It's us on the run{'\n'}
                    Under the midnight sun{'\n'}
                    {'\n'}
                    Faith is our name - they crash we rebuild{'\n'}
                    Stand your ground - it's kill or be killed{'\n'}
                    Eternal flame - still not fulfilled{'\n'}
                    No regrets - it's kill or be killed{'\n'}
                    {'\n'}
                    Without a hand in sadness{'\n'}
                    Without a light in blackness{'\n'}
                    Without a goal in vastness{'\n'}
                    Without a cure in madness{'\n'}
                    {'\n'}
                    It's all said and done{'\n'}
                    It's us on the run{'\n'}
                    Under the midnight sun{'\n'}
                    {'\n'}
                    Faith is our name - they crash we rebuild{'\n'}
                    Stand your ground - it's kill or be killed{'\n'}
                    Eternal flame - still not fulfilled{'\n'}
                    No regrets - it's kill or be killed
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>2. All As One (3:53)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    It's said{'\n'}
                    That freedom begins{'\n'}
                    Where subjugation ends{'\n'}
                    I'm no more a number{'\n'}
                    In your bloodstained hands{'\n'}
                    {'\n'}
                    I had{'\n'}
                    To walk through my hell{'\n'}
                    To cross my black sea{'\n'}
                    What you thought you've killed{'\n'}
                    Is still inside of me{'\n'}
                    {'\n'}
                    With a spark in the eye{'\n'}
                    With no fear to due{'\n'}
                    On strong wings of vengeance we fly{'\n'}
                    We resist, we defy{'\n'}
                    We're now aiming high{'\n'}
                    The target moves closer{'\n'}
                    To live all as one in this world{'\n'}
                    {'\n'}
                    You've tried{'\n'}
                    To manipulate{'\n'}
                    My thoughts day by day{'\n'}
                    But now I’m the hunter{'\n'}
                    And you are the prey{'\n'}
                    {'\n'}
                    Cause first{'\n'}
                    You've to learn to walk{'\n'}
                    Before you can run{'\n'}
                    If you pull the trigger{'\n'}
                    Don't look in the gun{'\n'}
                    {'\n'}
                    With a spark in the eye{'\n'}
                    With no fear to due{'\n'}
                    On strong wings of vengeance we fly{'\n'}
                    We resist, we defy{'\n'}
                    We're now aiming high{'\n'}
                    The target moves closer{'\n'}
                    To live all as one in this world
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>3. Carry On (3:56)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Sacred ties forever{'\n'}
                    We will stand together{'\n'}
                    In our mighty wall of sound{'\n'}
                    Into raging thunder{'\n'}
                    People stare and wonder{'\n'}
                    How this wheel's spinning around{'\n'}
                    {'\n'}
                    Some will try to bring us down{'\n'}
                    But we conquer every town{'\n'}
                    {'\n'}
                    Carry on - fight!{'\n'}
                    Strong we stay{'\n'}
                    Hold our flag high on our way{'\n'}
                    Carry on - fight!{'\n'}
                    'Til the battle's won{'\n'}
                    {'\n'}
                    We'll change the situation{'\n'}
                    We'll make our declaration{'\n'}
                    The strong at heart will never crawl{'\n'}
                    An outcry to every land{'\n'}
                    The day has come to take command{'\n'}
                    Let the final curtain fall{'\n'}
                    {'\n'}
                    Some will try to bring us down{'\n'}
                    But we conquer every town{'\n'}
                    {'\n'}
                    Carry on - fight!{'\n'}
                    Strong we stay{'\n'}
                    Hold our flag high on our way{'\n'}
                    Carry on - fight!{'\n'}
                    'Til the battle's won{'\n'}
                    {'\n'}
                    „We are one{'\n'}
                    An impregnable fortress{'\n'}
                    An indestructible alliance{'\n'}
                    We never give in{'\n'}
                    A union eternal{'\n'}
                    ....carry on"{'\n'}
                    {'\n'}
                    Carry on - fight!{'\n'}
                    Strong we stay{'\n'}
                    Hold our flag high on our way{'\n'}
                    Carry on - fight!{'\n'}
                    'Til the battle's won
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>4. Blast (ft. Peavy Wagner) (4:48)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Walking on the edge of light{'\n'}
                    Voices in my head ask for insight{'\n'}
                    Where do I come from?{'\n'}
                    What is the meaning of life?{'\n'}
                    What is my purpose?{'\n'}
                    And when will I arrive?{'\n'}
                    {'\n'}
                    Blast - in my chest I felt a blast{'\n'}
                    Blew away all internal unrest{'\n'}
                    Felt alive and strong for the first time cause of this{'\n'}
                    Blast - in my chest I felt a blast{'\n'}
                    Blew away all my doubts from the past{'\n'}
                    Unchained my heart and soul and suddenly{'\n'}
                    I felt the blast{'\n'}
                    {'\n'}
                    All my life I was a castaway{'\n'}
                    Steering to exponential decay{'\n'}
                    Looked for deliverance{'\n'}
                    Which was inside all along{'\n'}
                    It runs in my veins{'\n'}
                    Now I know where I belong{'\n'}
                    {'\n'}
                    Blast - in my chest I felt a blast{'\n'}
                    Blew away all internal unrest{'\n'}
                    Felt alive and strong for the first time cause of this{'\n'}
                    Blast - in my chest I felt a blast{'\n'}
                    Blew away all my doubts from the past{'\n'}
                    Unchained my heart and soul and suddenly{'\n'}
                    I felt the blast{'\n'}
                    {'\n'}
                    Don't be afraid of a change{'\n'}
                    Fear's an illusion{'\n'}
                    Open your heart and your mind{'\n'}
                    For revolution{'\n'}
                    {'\n'}
                    Looked for deliverance{'\n'}
                    Which was inside all along{'\n'}
                    It runs in my veins{'\n'}
                    Now I know where I belong{'\n'}
                    {'\n'}
                    Blast - in my chest I felt a blast{'\n'}
                    Blew away all internal unrest{'\n'}
                    Felt alive and strong for the first time cause of this{'\n'}
                    Blast - in my chest I felt a blast{'\n'}
                    Blew away all my doubts from the past{'\n'}
                    Unchained my heart and soul and suddenly{'\n'}
                    I felt the blast
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>5. Weakness And Shame	(4:15)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    I've been misled and betrayed{'\n'}
                    Been deceived and fooled, tricked and screwed{'\n'}
                    Their words became a fake parade{'\n'}
                    Overriding aim - a selfish game{'\n'}
                    {'\n'}
                    Keep on hiding behind your disguise{'\n'}
                    Your own lies{'\n'}
                    {'\n'}
                    Weakness and shame{'\n'}
                    Is what you leave behind in your name{'\n'}
                    Weakness and shame{'\n'}
                    In your miserable life{'\n'}
                    You still wonder who is to blame{'\n'}
                    {'\n'}
                    When ego is reigning your day{'\n'}
                    Followed by blind rage there's no change{'\n'}
                    Your anger is the only way{'\n'}
                    To ignore your fault through a cheap assault{'\n'}
                    {'\n'}
                    Keep on hiding behind your decline{'\n'}
                    You'll never shine{'\n'}
                    {'\n'}
                    Weakness and shame{'\n'}
                    Is what you leave behind in your name{'\n'}
                    Weakness and shame{'\n'}
                    In your miserable life{'\n'}
                    You still wonder who is to blame
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>6. Journey Of Life (5:42)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Shadows are falling{'\n'}
                    I hear echoes calling{'\n'}
                    Recalling wounds from the past{'\n'}
                    No blessings remaining{'\n'}
                    For a heart where it's raining{'\n'}
                    And constantly being harassed{'\n'}
                    {'\n'}
                    But still I’m standing{'\n'}
                    Never pretending{'\n'}
                    Stick to the signs in my quest{'\n'}
                    {'\n'}
                    Discover the journey of life{'\n'}
                    Seems so smooth but cuts like a knife{'\n'}
                    Find the strength, keep going on{'\n'}
                    A challenge to fight day by day{'\n'}
                    Against rocks that stand in your way{'\n'}
                    Odyssey to reach the sun{'\n'}
                    {'\n'}
                    Mounting through stormlands{'\n'}
                    Passing through badlands{'\n'}
                    Seeking for the rising star{'\n'}
                    At once it was all clear{'\n'}
                    A shine filled up its sphere{'\n'}
                    The old wound turned into a scar{'\n'}
                    {'\n'}
                    Now here I’m standing{'\n'}
                    For a trip never-ending{'\n'}
                    Nothing in life is bizarre{'\n'}
                    {'\n'}
                    Discover the journey of life{'\n'}
                    Seems so smooth but cuts like a knife{'\n'}
                    Find the strength, keep going on{'\n'}
                    A challenge to fight day by day{'\n'}
                    Against rocks that stand in your way{'\n'}
                    Odyssey to reach the sun
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>7. Rebel Soul	(5:13)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Days of disillusion{'\n'}
                    You live in confusion{'\n'}
                    Staring at this constant bane{'\n'}
                    In this final hour{'\n'}
                    Sense the strongest power{'\n'}
                    Growing from inside like never again{'\n'}
                    {'\n'}
                    Feel it running in your veins{'\n'}
                    See it's smashing all the chains{'\n'}
                    Enemy of all maniacal reigns{'\n'}
                    {'\n'}
                    Rebel soul - leading insurrection{'\n'}
                    Rebel soul - none will ever control{'\n'}
                    Rebel soul - everyone’s reflection{'\n'}
                    Born with a rebel soul{'\n'}
                    {'\n'}
                    It's a strong desire{'\n'}
                    Let it take you higher{'\n'}
                    Nothing really stays the same{'\n'}
                    Escape from the twilight{'\n'}
                    And turn wrong into right{'\n'}
                    Make sure nothing ever smothers your flame{'\n'}
                    {'\n'}
                    Feel it running in your veins{'\n'}
                    See it's smashing all the chains{'\n'}
                    Enemy of all maniacal reigns{'\n'}
                    {'\n'}
                    Rebel soul - leading insurrection{'\n'}
                    Rebel soul - none will ever control{'\n'}
                    Rebel soul - everyone’s reflection{'\n'}
                    Born with a rebel soul
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>8. Land Of Light (5:29)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Born of the rocks of this holy ground{'\n'}
                    For thousands of years providing{'\n'}
                    Philosophy and democracy{'\n'}
                    Revealed for the whole world to see{'\n'}
                    {'\n'}
                    We’re children of Mount Olympus{'\n'}
                    Children of gods with a vision{'\n'}
                    Ancient warriors showed us the way{'\n'}
                    How to treat those who lead us astray{'\n'}
                    {'\n'}
                    Hellas, the land of light{'\n'}
                    Land of utopia{'\n'}
                    Home of antique free spirits and hyperopia{'\n'}
                    Hellas, the land of light{'\n'}
                    That knows no phobia{'\n'}
                    Land of heroes from Sparta and Macedonia{'\n'}
                    {'\n'}
                    His queen said to king Leonidas{'\n'}
                    „Come back with your shield or on it"{'\n'}
                    The battle is always to the last breath{'\n'}
                    Fight for dignity or wait for death{'\n'}
                    {'\n'}
                    We are children of Mount Olympus{'\n'}
                    Of king Alexander the Great{'\n'}
                    „There’s nothing impossible to him who will try"{'\n'}
                    Mark his words - raise your fist to the sky{'\n'}
                    {'\n'}
                    Hellas, the land of light{'\n'}
                    Land of utopia{'\n'}
                    Home of antique free spirits and hyperopia{'\n'}
                    Hellas, the land of light{'\n'}
                    That knows no phobia{'\n'}
                    Land of heroes from Sparta and Macedonia
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>9. Slaves To Addiction (4:26)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Reading the news and watching TV{'\n'}
                    A surface to fool with our mind{'\n'}
                    No escape - attacked from all sides{'\n'}
                    The free will's about to resign{'\n'}
                    {'\n'}
                    Forces of black take over the field{'\n'}
                    With order to manipulate{'\n'}
                    Last chance to suffocate{'\n'}
                    The concept before it's too late{'\n'}
                    {'\n'}
                    In a cell with no key{'\n'}
                    Still you think that you're free{'\n'}
                    {'\n'}
                    Slaves to addiction{'\n'}
                    We're too blind to see the pain{'\n'}
                    Behind a hidden chain{'\n'}
                    Slaves to addiction{'\n'}
                    In a world beyond the sun{'\n'}
                    As all is sad and gone{'\n'}
                    {'\n'}
                    Darkness and dust confusing my brain{'\n'}
                    My freedom is taken away{'\n'}
                    Enough is enough, I’ll go my own way{'\n'}
                    Deliver from bondage today{'\n'}
                    {'\n'}
                    In a cell with no key{'\n'}
                    Still you think that you're free{'\n'}
                    {'\n'}
                    Slaves to addiction{'\n'}
                    We're too blind to see the pain{'\n'}
                    Behind a hidden chain{'\n'}
                    Slaves to addiction{'\n'}
                    In a world beyond the sun{'\n'}
                    As all is sad and gone{'\n'}
                    {'\n'}
                    We're the chosen sons of destiny{'\n'}
                    We're the defiance of the night{'\n'}
                    We’re no prisoners of your endless blight{'\n'}
                    Let's wipe out this parasite{'\n'}
                    {'\n'}
                    Slaves to addiction{'\n'}
                    We're too blind to see the pain{'\n'}
                    Behind a hidden chain{'\n'}
                    Slaves to addiction{'\n'}
                    In a world beyond the sun{'\n'}
                    As all is sad and gone
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>10. Fame (Irene Cara cover) (4:33)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Baby, look at me{'\n'}
                    And tell me what you see{'\n'}
                    You ain't seen the best of me yet{'\n'}
                    Give me time{'\n'}
                    I'll make you forget the rest{'\n'}
                    {'\n'}
                    I got more in me{'\n'}
                    And you can set it free{'\n'}
                    I can catch the moon in my hand{'\n'}
                    Don't you know who I am{'\n'}
                    {'\n'}
                    Remember my name, fame{'\n'}
                    I'm gonna live forever{'\n'}
                    I'm gonna learn how to fly, high{'\n'}
                    {'\n'}
                    I feel it comin' together{'\n'}
                    People will see me and cry, fame{'\n'}
                    I'm gonna make it to heaven{'\n'}
                    Light up the sky like a flame, fame{'\n'}
                    I'm gonna live forever{'\n'}
                    Baby, remember my name{'\n'}
                    Remember, remember, remember, remember{'\n'}
                    Remember, remember, remember, remember{'\n'}
                    {'\n'}
                    Baby, hold me tight{'\n'}
                    'Cause you can make it right{'\n'}
                    You can shoot me straight to the top{'\n'}
                    Give me love and take all I got to give{'\n'}
                    {'\n'}
                    Baby, I'll be tough{'\n'}
                    Too much is not enough, no{'\n'}
                    I can ride your heart 'till it breaks{'\n'}
                    Ooh, I got what it takes{'\n'}
                    {'\n'}
                    Fame{'\n'}
                    I'm gonna live forever{'\n'}
                    I'm gonna learn how to fly, high{'\n'}
                    I feel it comin' together{'\n'}
                    People will see me and cry, fame{'\n'}
                    {'\n'}
                    I'm gonna make it to heaven{'\n'}
                    Light up the sky like a flame, fame{'\n'}
                    I'm gonna live forever{'\n'}
                    Baby, remember my name{'\n'}
                    Remember, remember, remember, remember{'\n'}
                    Remember, remember, remember{'\n'}
                    {'\n'}
                    Fame{'\n'}
                    I'm gonna live forever{'\n'}
                    I'm gonna learn how to fly, high{'\n'}
                    {'\n'}
                    I feel it comin' together{'\n'}
                    People will see me and cry, fame{'\n'}
                    I'm gonna make it to heaven{'\n'}
                    Light up the sky like a flame, fame{'\n'}
                    I'm gonna live forever{'\n'}
                    Baby, remember my name{'\n'}
                    Remember, remember, remember, remember{'\n'}
                    Remember, remember, remember, remember (remember my name){'\n'}
                    {'\n'}
                    Fame{'\n'}
                    I'm gonna live forever (fame){'\n'}
                    I'm gonna learn how to fly, high{'\n'}
                    {'\n'}
                    I feel it comin' together (fame){'\n'}
                    People will see me and cry, fame{'\n'}
                    I'm gonna make it to heaven (fame){'\n'}
                    Light up the sky like a flame, fame{'\n'}
                    I'm gonna live forever (fame){'\n'}
                    Baby, remember my name{'\n'}
                    {'\n'}
                    Fame{'\n'}
                    I'm gonna live forever (fame){'\n'}
                    I'm gonna learn how to fly, high{'\n'}
                    I feel it comin' together (fame){'\n'}
                    People will see me and cry, fame
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>11. World On Fire	(5:49)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    All ancient lights we had{'\n'}
                    In times faded to grey{'\n'}
                    Harvest the decay{'\n'}
                    {'\n'}
                    The universal wisdom{'\n'}
                    Got lost along with freedom{'\n'}
                    Looking for a sign{'\n'}
                    Staring at the mess we left behind{'\n'}
                    {'\n'}
                    Roving in shadows{'\n'}
                    Of older heroes{'\n'}
                    To write the final rhyme{'\n'}
                    {'\n'}
                    World on fire - all prayers had been spoken{'\n'}
                    Burning desire - as smoke covers my eyes{'\n'}
                    World on fire - the giant is awoken{'\n'}
                    Falling empire - new kingdom will arise{'\n'}
                    {'\n'}
                    Wordless the whispers{'\n'}
                    Of the laws that bind us all{'\n'}
                    Laughing as we fall{'\n'}
                    {'\n'}
                    We have to end this nightmare{'\n'}
                    This hellish ride to nowhere{'\n'}
                    Finally realize{'\n'}
                    The mission is „create and visualize"{'\n'}
                    {'\n'}
                    Another daybreak{'\n'}
                    To make the ground shake{'\n'}
                    Remember who we are{'\n'}
                    {'\n'}
                    World on fire - all prayers had been spoken{'\n'}
                    Burning desire - as smoke covers my eyes{'\n'}
                    World on fire - the giant is awoken{'\n'}
                    Falling empire - new kingdom will arise{'\n'}
                    {'\n'}
                    Earth will turn to sand{'\n'}
                    To a barren land{'\n'}
                    Banned in darkness to the end of time{'\n'}
                    Humanity unite{'\n'}
                    Illuminate the night{'\n'}
                    The kindling of this flame will be divine
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>12. La Fuerza De Ser (5:41)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Ya son muchos aňos{'\n'}
                    Te sientes cansando{'\n'}
                    Y harto de tanto esperar{'\n'}
                    Alguna salida{'\n'}
                    O la alternativa{'\n'}
                    No puede haber tanta maldad{'\n'}
                    Quizá nada es cierto{'\n'}
                    Nos venden desprecio{'\n'}
                    Y un mundo de odio y maldad{'\n'}
                    {'\n'}
                    Escucha la voz interior{'\n'}
                    Que te guía a un mundo mejor{'\n'}
                    Más allá de lo que ves{'\n'}
                    Y sueňa con tu realidad{'\n'}
                    Lo que quieras podrás lograr{'\n'}
                    Solo cree en tu poder{'\n'}
                    {'\n'}
                    Fue tal vez conciencia{'\n'}
                    O quizá la inocencia{'\n'}
                    Que despertó curiosidad{'\n'}
                    Ya no eres el mismo{'\n'}
                    Hay algo distinto{'\n'}
                    Ya no sientes la soledad{'\n'}
                    Y poco a poco{'\n'}
                    Cerrando los ojos{'\n'}
                    Descubres la fuerza de ser
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card.Content>
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="METAL DOME (2015)" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*1200)/1200 }} source={require('../assets/metal_dome.jpg')} />
          <Card.Content style={myCss.cardContent}>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>1. Screamers In The Storm (5:15)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Your life is in danger{'\n'}
                    You're tired of barriers{'\n'}
                    Your friend's a stranger{'\n'}
                    Your last chance disappears{'\n'}
                    {'\n'}
                    No coexistence{'\n'}
                    Just prey for shark{'\n'}
                    No last resistance{'\n'}
                    To the dark{'\n'}
                    {'\n'}
                    Don't rely on their word{'\n'}
                    It's all so absurd{'\n'}
                    Just try to reveal{'\n'}
                    What they try to conceal{'\n'}
                    {'\n'}
                    Never follow the world's rules{'\n'}
                    Like a sheep among the wolves{'\n'}
                    Set yourself free day by day{'\n'}
                    Be no part of this decay{'\n'}
                    Be a screamer in the storm{'\n'}
                    Tear down and reform{'\n'}
                    {'\n'}
                    All cursed in stillness{'\n'}
                    To live by the chain{'\n'}
                    Drowned in the sadness{'\n'}
                    Of our brain{'\n'}
                    {'\n'}
                    Don't rely on their word{'\n'}
                    It's all so absurd{'\n'}
                    Just try to reveal{'\n'}
                    What they try to conceal{'\n'}
                    {'\n'}
                    Never follow the world's rules{'\n'}
                    Like a sheep among the wolves{'\n'}
                    Set yourself free day by day{'\n'}
                    Be no part of this decay{'\n'}
                    Be a screamer in the storm{'\n'}
                    Tear down and reform{'\n'}
                    {'\n'}
                    Encircled blackness everywhere{'\n'}
                    No command! No man's land!{'\n'}
                    Voices screaming in the air{'\n'}
                    {'\n'}
                    Never follow the world's rules{'\n'}
                    Like a sheep among the wolves{'\n'}
                    Set yourself free day by day{'\n'}
                    Be no part of this decay{'\n'}
                    Be a screamer in the storm{'\n'}
                    Tear down and reform
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>2. Metal Dome (5:52)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    "A long time ago...as the powers of universe united...{'\n'}
                    They created a sound that was meant to stay forever{'\n'}
                    A unity of rebels was born{'\n'}
                    As decades past by, this unity grew stronger and until today,{'\n'}
                    Wherever this people meet, they turn the place...{'\n'}
                    ...into a metal dome"{'\n'}
                    {'\n'}
                    Far away on holy ground{'\n'}
                    Miles away you hear the sound{'\n'}
                    There's a church one of a kind{'\n'}
                    Enter and free your mind{'\n'}
                    {'\n'}
                    A place full of inspiration{'\n'}
                    Under no one's obligation{'\n'}
                    Starting there where others end{'\n'}
                    This is what we demand{'\n'}
                    {'\n'}
                    In this majesty and might{'\n'}
                    We're baptized in strength and pride{'\n'}
                    {'\n'}
                    Praising choirs fill the air{'\n'}
                    Tunes of thunder everywhere{'\n'}
                    That's the place I call my home{'\n'}
                    Skin of leather - blazing chrome{'\n'}
                    A common bound - no segregation{'\n'}
                    One sound - one united nation{'\n'}
                    This is where I'm feeling home{'\n'}
                    Welcome to the metal dome{'\n'}
                    {'\n'}
                    It's the temple of creation{'\n'}
                    Only here you'll find salvation{'\n'}
                    Freedom at its purest sense{'\n'}
                    Honor and deliverance{'\n'}
                    {'\n'}
                    A mass of eternal pleasure{'\n'}
                    Come on in and share the treasure{'\n'}
                    Feel the power of the gods{'\n'}
                    We stand against all odds{'\n'}
                    {'\n'}
                    In this majesty and might{'\n'}
                    We're baptized in strength and pride{'\n'}
                    {'\n'}
                    Praising choirs fill the air{'\n'}
                    Tunes of thunder everywhere{'\n'}
                    That's the place I call my home{'\n'}
                    Skin of leather - blazing chrome{'\n'}
                    A common bound - no segregation{'\n'}
                    One sound - one united nation{'\n'}
                    This is where I'm feeling home{'\n'}
                    Welcome to the metal dome
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>3. Black Pearl (5:28)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Near dark{'\n'}
                    And the wind is howling{'\n'}
                    Black mark{'\n'}
                    Branded in his mind{'\n'}
                    His mission is to save mankind{'\n'}
                    {'\n'}
                    Grey skies{'\n'}
                    Over red horizons{'\n'}
                    Strength dies{'\n'}
                    In the pouring rain{'\n'}
                    But in his eyes no signs of strain{'\n'}
                    {'\n'}
                    Sent by spirits from above{'\n'}
                    To find a pearl with secret powers{'\n'}
                    Takes his chances to hang tough{'\n'}
                    With the urge kept deep inside{'\n'}
                    {'\n'}
                    Now he found his way for a better day{'\n'}
                    Over seas and through the highest mountains{'\n'}
                    Ready to fulfil with his iron will{'\n'}
                    To succeed and to find the black pearl{'\n'}
                    {'\n'}
                    War cries{'\n'}
                    Against death and darkness{'\n'}
                    Strange eyes{'\n'}
                    Etched in every stone{'\n'}
                    Await the warrior unknown{'\n'}
                    {'\n'}
                    Last fight{'\n'}
                    Between good and evil{'\n'}
                    First sight{'\n'}
                    Of a helping hand{'\n'}
                    His legend's heard across the land{'\n'}
                    {'\n'}
                    Sent by spirits from above{'\n'}
                    To find a pearl with secret powers{'\n'}
                    Takes his chances to hang tough{'\n'}
                    With the urge kept deep inside{'\n'}
                    {'\n'}
                    Now he found his way for a better day{'\n'}
                    Over seas and through the highest mountains{'\n'}
                    Ready to fulfil with his iron will{'\n'}
                    To succeed and to find the black pearl
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>4. Freedom Call (6:14)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    For me{'\n'}
                    You would walk through fire to see my devise{'\n'}
                    Would betray your god no matter what's the price{'\n'}
                    Where there's justice still my breath is in the air{'\n'}
                    I can vanquish all the sorrow and despair{'\n'}
                    {'\n'}
                    But I{'\n'}
                    I am just a word if you will loose your aim{'\n'}
                    "Serve no masters" is the meaning of my name{'\n'}
                    For all destiny and love I am the key{'\n'}
                    If you got the strength look what they've done to me{'\n'}
                    {'\n'}
                    Freedom{'\n'}
                    Freedom{'\n'}
                    Freedom{'\n'}
                    Freedom{'\n'}
                    {'\n'}
                    Shame!{'\n'}
                    We've lost our values look what we became{'\n'}
                    The flame in our hearts isn’t the same{'\n'}
                    Fight!{'\n'}
                    Noone can tell you if it's wrong or right{'\n'}
                    Ignite!{'\n'}
                    The spark forgotten inside{'\n'}
                    {'\n'}
                    Head up high - be the dissenter{'\n'}
                    In our code there's no surrender{'\n'}
                    {'\n'}
                    Freedom call{'\n'}
                    Strikes a lightning in us all{'\n'}
                    We can sail across the oceans, cause a wave that breaks the wall{'\n'}
                    Freedom call{'\n'}
                    Strikes a lightning in us all{'\n'}
                    Just one step into the circle, and the rock begins to roll{'\n'}
                    Insane{'\n'}
                    Is how we turned all the sunlight to ra{'\n'}
                    Arraign{'\n'}
                    All those who poisoned your brain{'\n'}
                    Ally!{'\n'}
                    Join forces, be a needle in their eye{'\n'}
                    Retry!{'\n'}
                    We don't have to live this lie{'\n'}
                    {'\n'}
                    Head up high - be the dissenter{'\n'}
                    In our code there's no surrender
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>5. Racing With Destiny (5:10)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    We've been led to live in segregation{'\n'}
                    Only torture and tears in our eyes{'\n'}
                    Left alone amongst this devastation{'\n'}
                    Watching the human flame as it slowly dies{'\n'}
                    {'\n'}
                    Providence points to our destination{'\n'}
                    Can't you see you've got to search to find{'\n'}
                    Vanish the haze of intoxication{'\n'}
                    Clear your sight and you'll see what lies behind{'\n'}
                    {'\n'}
                    So let us all sing along{'\n'}
                    As we are racing with destiny{'\n'}
                    Discover where we belong{'\n'}
                    Cause unity sets free{'\n'}
                    We are a crowd millions strong{'\n'}
                    When we are racing with destiny{'\n'}
                    So many hearts can't be wrong{'\n'}
                    Our unity at last will set us free...{'\n'}
                    {'\n'}
                    Raise your hands search out for revelation{'\n'}
                    The golden key is at the end of the chain{'\n'}
                    Unlock the gateways for the infestation{'\n'}
                    Where there will be no pain, ever again{'\n'}
                    {'\n'}
                    So let us all sing along{'\n'}
                    As we are racing with destiny{'\n'}
                    Discover where we belong{'\n'}
                    Cause unity sets free{'\n'}
                    We are a crowd millions strong{'\n'}
                    When we are racing with destiny{'\n'}
                    So many hearts can't be wrong{'\n'}
                    Our unity at last will set us free...{'\n'}
                    {'\n'}
                    ...and now we're forced together, stand side by side{'\n'}
                    This bound will last forever till our worlds collide{'\n'}
                    {'\n'}
                    Raise your hands search out for revelation{'\n'}
                    The golden key is at the end of the chain{'\n'}
                    Unlock the gateways for the infestation{'\n'}
                    Where there will be no pain, ever again{'\n'}
                    {'\n'}
                    So let us all sing along{'\n'}
                    As we are racing with destiny{'\n'}
                    Discover where we belong{'\n'}
                    Cause unity sets free{'\n'}
                    We are a crowd millions strong{'\n'}
                    When we are racing with destiny{'\n'}
                    So many hearts can't be wrong{'\n'}
                    Our unity at last will set us free...
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>6. No More Fear (4:27)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Treating us like fools - spreading all the fear{'\n'}
                    Breaking all the rules - while our rights disappear{'\n'}
                    Pain, grief, suffering but I ain't gonna take anymore{'\n'}
                    {'\n'}
                    A new disease around - they've already got the cure{'\n'}
                    Like prophets they may sound - but I am not so sure{'\n'}
                    How much more time will it take to realize what we ignore?{'\n'}
                    {'\n'}
                    We've got the power{'\n'}
                    Transform darkness to light{'\n'}
                    Let's change this world{'\n'}
                    Starting tonight{'\n'}
                    {'\n'}
                    No more fear{'\n'}
                    They frighten us to take our life's control{'\n'}
                    No more fear{'\n'}
                    All we need is hidden in our soul{'\n'}
                    {'\n'}
                    Make up your own mind - read between the lines{'\n'}
                    See what lies behind - recognize the signs{'\n'}
                    Heading straight to a godless place of no return{'\n'}
                    {'\n'}
                    Time to make some noise - it is them or me{'\n'}
                    Time to make a choice - I will disagree{'\n'}
                    Listen to your inner voice and give the handle another turn{'\n'}
                    {'\n'}
                    We've got the power{'\n'}
                    Transform darkness to light{'\n'}
                    Let's change this world{'\n'}
                    Starting tonight{'\n'}
                    {'\n'}
                    No more fear{'\n'}
                    They frighten us to take our life's control{'\n'}
                    No more fear{'\n'}
                    All we need is hidden in our soul
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>7. Mere Reflection (4:39)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Cuts like a knife{'\n'}
                    I lie here dreaming for a while{'\n'}
                    About all the things that matter in my life and I...{'\n'}
                    I awaited the day for the price I had to pay{'\n'}
                    Just to see them come alive{'\n'}
                    Being born but then they die{'\n'}
                    {'\n'}
                    So I realize{'\n'}
                    All dreams can turn to ashes when they're gone{'\n'}
                    And a new beginning is at dawn{'\n'}
                    {'\n'}
                    Deal of joy unsigned, once again{'\n'}
                    Leave the past behind, I wish I can{'\n'}
                    It was a mere reflection of a false perfection{'\n'}
                    And a change of plans{'\n'}
                    {'\n'}
                    Woke up today{'\n'}
                    With sadness marked right in my face{'\n'}
                    But behind this mask I'm strong, I have to keep the pace{'\n'}
                    A new initiation as the sun shines so bright{'\n'}
                    And the dark is wasted by the gleam and the light{'\n'}
                    So I realize dreams are born when I'm losing my aim{'\n'}
                    Like a phoenix rising from the flames{'\n'}
                    {'\n'}
                    Deal of joy unsigned, once again{'\n'}
                    Leave the past behind, I wish I can{'\n'}
                    It was a mere reflection of a false perfection{'\n'}
                    And a change of plans{'\n'}
                    {'\n'}
                    How can I turn my back to fate{'\n'}
                    And then rely on something fake{'\n'}
                    Asking myself if this could be{'\n'}
                    And why it's happening to me{'\n'}
                    {'\n'}
                    Don't try to preach what you ignore{'\n'}
                    I've heard these words of yours before{'\n'}
                    I know the mirror never lies{'\n'}
                    If you see truth with your own eyes{'\n'}
                    {'\n'}
                    Deal of joy unsigned, once again{'\n'}
                    Leave the past behind, I wish I can{'\n'}
                    It was a mere reflection of a false perfection{'\n'}
                    And a change of plans{'\n'}
                    {'\n'}
                    Plans so strong that bound me into this web of lies{'\n'}
                    But now I'm free - the inner drive never dies
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>8. Dust And Bones (3:45)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Don't try to convince me to stay, I won't{'\n'}
                    My life is a journey away from home{'\n'}
                    I'm never rewinding the time{'\n'}
                    But I know where I come from{'\n'}
                    As old vision is past its prime{'\n'}
                    I'm just passing through this world{'\n'}
                    {'\n'}
                    Tomorrow's the shine of today, no lie{'\n'}
                    We live and we die all the same, we fly{'\n'}
                    {'\n'}
                    I've seen old things turning to dust{'\n'}
                    It has changed my point of view{'\n'}
                    Delusions belong to the past it's being{'\n'}
                    A failure or be true?{'\n'}
                    {'\n'}
                    Life is dust and bones{'\n'}
                    Life is dust and bones{'\n'}
                    {'\n'}
                    There's some things about which I care{'\n'}
                    And some things that I beware{'\n'}
                    I'm part of the eternal birth{'\n'}
                    Pass the spirit around the earth{'\n'}
                    {'\n'}
                    Life is dust and bones{'\n'}
                    Life is dust and bones
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>9. Blood On Your Name (5:01)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    With lies and suits they try to rule our lives{'\n'}
                    To all of them we seem so plain{'\n'}
                    But only truth and honesty survives{'\n'}
                    It's time to brand it in their brains{'\n'}
                    {'\n'}
                    Try to hypnotize me with your lies{'\n'}
                    Burn me! - From the ashes I'll arise{'\n'}
                    Hate and fear become allies{'\n'}
                    {'\n'}
                    All these years standing in line{'\n'}
                    Your shade was over my shine{'\n'}
                    Was a prisoner but I don't feel the same{'\n'}
                    All these years m roar was rough{'\n'}
                    Think I suffered long enough{'\n'}
                    I became a beast you will never tame{'\n'}
                    Blood on your name{'\n'}
                    {'\n'}
                    Raised us all - gave us fake hopes and dreams{'\n'}
                    It's over now - bid you farewell{'\n'}
                    Our turn now to listen to your screams{'\n'}
                    And send your ashes straight to hell{'\n'}
                    {'\n'}
                    Try to hypnotize me with your lies{'\n'}
                    Burn me! - From the ashes I'll arise{'\n'}
                    Hate and fear become allies
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>10. Legacy Of Night (4:23)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    There was a star in the sky{'\n'}
                    Lonely and brighter than all{'\n'}
                    That bright star was mine{'\n'}
                    A long time ago{'\n'}
                    {'\n'}
                    There is a light in our heart{'\n'}
                    Enlighting our warrior soul{'\n'}
                    To help us restart{'\n'}
                    Achieving our goal{'\n'}
                    {'\n'}
                    Stars, legacy of night, from another world{'\n'}
                    Travellers, servants of the light, let the truth unfold{'\n'}
                    {'\n'}
                    Can you feel it all in your veins?{'\n'}
                    Can you hear the golden age call?{'\n'}
                    It's breaking the chains{'\n'}
                    For once and for all{'\n'}
                    {'\n'}
                    Stars, legacy of night, from another world{'\n'}
                    Travellers, servants of the light, let the truth unfold{'\n'}
                    {'\n'}
                    Just leave all the sorrows behind{'\n'}
                    Escape all your fears and start leading the blind{'\n'}
                    With servants of light by our side{'\n'}
                    We are saving our kind{'\n'}
                    {'\n'}
                    Stars, legacy of night, from another world{'\n'}
                    Travellers, servants of the light, let the truth unfold{'\n'}
                    Stars, legacy of night, from another world{'\n'}
                    Travellers, servants of the light, let the truth unfold
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>11. You Won't Break Me Down (5:10)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Don't try to bring me to my knees{'\n'}
                    The attempt will be in vain{'\n'}
                    Don't promise me tomorrow{'\n'}
                    I've seen a lot of pain{'\n'}
                    {'\n'}
                    I've always been a fighter{'\n'}
                    My soul is full of scars{'\n'}
                    Reach for the skies on fire{'\n'}
                    And still touching the stars{'\n'}
                    {'\n'}
                    Of steel is my will{'\n'}
                    Till I get my fill{'\n'}
                    {'\n'}
                    You won't break me down{'\n'}
                    I will never let you run my life for me{'\n'}
                    You won't break me down{'\n'}
                    Got to accept that this is how it has to be{'\n'}
                    You won't break me down{'\n'}
                    You think I don't get it, you pushing me around{'\n'}
                    Better know that...{'\n'}
                    You won't break me down{'\n'}
                    {'\n'}
                    Prefer to live in heaven{'\n'}
                    But I'm not afraid of hell{'\n'}
                    If you listen to my heartbeat{'\n'}
                    You will hear the rebel yell{'\n'}
                    {'\n'}
                    So there is no sense in trying{'\n'}
                    Many others did before{'\n'}
                    You can try to win the battle{'\n'}
                    But for sure you'll lose the war{'\n'}
                    {'\n'}
                    You won't break me down{'\n'}
                    I will never let you run my life for me{'\n'}
                    You won't break me down{'\n'}
                    Got to accept that this is how it has to be{'\n'}
                    You won't break me down{'\n'}
                    You think I don't get it, you pushing me around{'\n'}
                    Better know that...{'\n'}
                    You won't break me down{'\n'}
                    {'\n'}
                    We're living on different codes{'\n'}
                    We're walking on different roads{'\n'}
                    Selfishness rules in your mind{'\n'}
                    But you'll never rule over mankind{'\n'}
                    {'\n'}
                    You won't break me down{'\n'}
                    I will never let you run my life for me{'\n'}
                    You won't break me down{'\n'}
                    Got to accept that this is how it has to be{'\n'}
                    You won't break me down{'\n'}
                    You think I don't get it, you pushing me around{'\n'}
                    Better know that...{'\n'}
                    You won't break me down
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>12. Call Of Nature (4:44)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Saw the devine{'\n'}
                    The source of time{'\n'}
                    Witnessing how life started{'\n'}
                    From the first dawn{'\n'}
                    Till all is gone{'\n'}
                    Everything carved in stone{'\n'}
                    {'\n'}
                    Erase the grey{'\n'}
                    Wipe tears away{'\n'}
                    Paint the ceiling with kindness{'\n'}
                    Delight is near{'\n'}
                    It's crystal clear{'\n'}
                    Nature's call will appear{'\n'}
                    {'\n'}
                    Secrets only a few realize{'\n'}
                    Spirits bound to the nature{'\n'}
                    The voice within your soul gets in disguise{'\n'}
                    And you feel the call of nature arise{'\n'}
                    {'\n'}
                    The unions entry has opened the doors{'\n'}
                    Universe is awaiting{'\n'}
                    The edge of darkness, the ending of wars{'\n'}
                    Guides us to heaven's shores{'\n'}
                    {'\n'}
                    Love is the key of what will be{'\n'}
                    Recover ancient donations{'\n'}
                    Along the trace{'\n'}
                    Left to replace{'\n'}
                    To end this inhuman race
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card.Content>
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="REGENERATION (2011)" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*1200)/1200 }} source={require('../assets/regeneration.jpg')} />
          <Card.Content style={myCss.cardContent}>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>1. We Won't Fall (4:09)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    It's your idea - we need to be mistaken{'\n'}
                    You always got to be misunderstood{'\n'}
                    You thought we weren't good enough to face you{'\n'}
                    Who made you judge to say what's right or wrong{'\n'}
                    {'\n'}
                    But the lightning always strikes twice{'\n'}
                    Be prepared to face the truth{'\n'}
                    {'\n'}
                    Now{'\n'}
                    We won't fall{'\n'}
                    The gods are on our side{'\n'}
                    We are the youth - we are the future{'\n'}
                    Now{'\n'}
                    We won't fall{'\n'}
                    The power is in our hands{'\n'}
                    We have our dreams - we claim the future{'\n'}
                    {'\n'}
                    We're on to you - wake up we're not that stupid{'\n'}
                    Stop lying is it so hard for you to see{'\n'}
                    We've had enough of all those accusations{'\n'}
                    Just take your lies and shove them up your ass{'\n'}
                    {'\n'}
                    But the lightning always strikes twice{'\n'}
                    Be prepared to the truth{'\n'}
                    {'\n'}
                    Now{'\n'}
                    We won't fall{'\n'}
                    The gods are on our side{'\n'}
                    We are the youth - we are the future{'\n'}
                    Now{'\n'}
                    We won't fall{'\n'}
                    The power is in our hands{'\n'}
                    We have our dreams - we claim the future{'\n'}
                    {'\n'}
                    Who made you judge to say{'\n'}
                    What's right or wrong{'\n'}
                    Only two words coming to{'\n'}
                    My mind now... Fuck "yeah"{'\n'}
                    {'\n'}
                    But the lightning always strikes twice{'\n'}
                    Be prepared to the truth{'\n'}
                    {'\n'}
                    Now{'\n'}
                    We won't fall{'\n'}
                    The gods are on our side{'\n'}
                    We are the youth - we are the future{'\n'}
                    Now{'\n'}
                    We won't fall{'\n'}
                    The power is in our hands{'\n'}
                    We have our dreams - we claim the future
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>2. Theater Of Pain (4:41)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    What do you see when you look in my eyes{'\n'}
                    A brand new future{'\n'}
                    How came you haven’t yet figure that out{'\n'}
                    It's like a picture{'\n'}
                    {'\n'}
                    Is it so hard for you to understand{'\n'}
                    I'm no messiah heaven sent{'\n'}
                    Full of arrogance and lies{'\n'}
                    Your life before my eyes{'\n'}
                    {'\n'}
                    Welcome to the other side{'\n'}
                    You will see two worlds collide{'\n'}
                    {'\n'}
                    In the theatre of pain{'\n'}
                    The demon smiles - your hands are shaking{'\n'}
                    We're the monsters you've created{'\n'}
                    We're here to take your soul{'\n'}
                    {'\n'}
                    Starting the show{'\n'}
                    Watch it reflecting your life gone wasted{'\n'}
                    The curtain falls, the lights are out{'\n'}
                    And the crowd is gone{'\n'}
                    {'\n'}
                    Now face the truth - fame is just fading away{'\n'}
                    Nobody liked your crappy play{'\n'}
                    Open eyes and realize{'\n'}
                    No time to compromise{'\n'}
                    {'\n'}
                    Take the script back in your hand{'\n'}
                    You will change the bitter end{'\n'}
                    {'\n'}
                    In the theatre of pain{'\n'}
                    The demon smiles - your hands are shaking{'\n'}
                    We're the monsters you've created{'\n'}
                    We're here to take your soul{'\n'}
                    {'\n'}
                    Welcome to your show - your life is set on stage{'\n'}
                    Ready or not - you must perform{'\n'}
                    Where desire lies behind in the theatre of pain{'\n'}
                    {'\n'}
                    In the theatre of pain{'\n'}
                    The demon smiles - your hands are shaking{'\n'}
                    We're the monsters you've created{'\n'}
                    We're here to take your soul
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>3. Where Angels Fly (3:43)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Always pushed the dark{'\n'}
                    Not the last in line, someday{'\n'}
                    We'll be sharing our{'\n'}
                    Thoughts in heaven{'\n'}
                    {'\n'}
                    Always forced to do this{'\n'}
                    Always tricked to do that{'\n'}
                    Patience without limitations{'\n'}
                    {'\n'}
                    Hold all your strength{'\n'}
                    Until one day{'\n'}
                    The time will come...{'\n'}
                    We'll find the way{'\n'}
                    {'\n'}
                    We'll be there together{'\n'}
                    Where angels fly{'\n'}
                    Where all desires{'\n'}
                    Are coming to life{'\n'}
                    Where our dreams can be alive{'\n'}
                    And stuck in our hearts{'\n'}
                    Forever{'\n'}
                    {'\n'}
                    Now society is blind saying{'\n'}
                    It doesn't worth a dime{'\n'}
                    Let you rot in your rusty cages{'\n'}
                    Time to open our eyes{'\n'}
                    Time to open our souls{'\n'}
                    Receive what we{'\n'}
                    Deserved for ages{'\n'}
                    {'\n'}
                    Release your strength{'\n'}
                    Today is the day{'\n'}
                    The time has come{'\n'}
                    Now walk your way{'\n'}
                    {'\n'}
                    We'll be there together{'\n'}
                    Where angels fly{'\n'}
                    Where all desires{'\n'}
                    Are coming to life{'\n'}
                    Where our dreams can be alive{'\n'}
                    And stuck in our hearts{'\n'}
                    Forever
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>4. Regeneration (1:50)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    (instrumental)
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>5. Starchild (4:58)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Welcome to a polluting nation{'\n'}
                    To a mindcontrol{'\n'}
                    Where we gain our own salvation{'\n'}
                    On another fall{'\n'}
                    {'\n'}
                    Do not seek the mighty justice{'\n'}
                    It's been gone away{'\n'}
                    All you find's some frightened faces{'\n'}
                    That's the world today{'\n'}
                    {'\n'}
                    Don't lose your faith just believe in yourself{'\n'}
                    New chance arrives move your way{'\n'}
                    Forget the past a new day will last{'\n'}
                    Your wish granted by falling stars{'\n'}
                    {'\n'}
                    I am a starchild from the sky above{'\n'}
                    I'm on my way to bring you light{'\n'}
                    Now it's the time for regeneration{'\n'}
                    Forever shine in your eyes{'\n'}
                    {'\n'}
                    Don't you try to misconcept us{'\n'}
                    With a bunch of lies{'\n'}
                    All we want is a better future{'\n'}
                    Nobody denies{'\n'}
                    {'\n'}
                    Heading for the end of days{'\n'}
                    The message has been sent{'\n'}
                    Punishment will be objective{'\n'}
                    No time to regret{'\n'}
                    {'\n'}
                    Prepare yourself to stand at the edge of time{'\n'}
                    Witness the new life begins{'\n'}
                    Erase the lie, the tear from your eye{'\n'}
                    I'll take your soul up to the sky{'\n'}
                    {'\n'}
                    I am a starchild from the sky above{'\n'}
                    I'm on my way to bring you light{'\n'}
                    Now it's the time for regeneration{'\n'}
                    Forever shine in your eyes
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>6. Speak Of The Devil (4:49)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Hell's rising from{'\n'}
                    Within your soul{'\n'}
                    Your eyes are blazing fire{'\n'}
                    Your tweaking mind{'\n'}
                    Is dying in your head{'\n'}
                    {'\n'}
                    You always walk the wicked way{'\n'}
                    And acting like a fool{'\n'}
                    Your poison's in your veins{'\n'}
                    So shut your mouth I'm done with you{'\n'}
                    Walk away{'\n'}
                    {'\n'}
                    Speak of the devil{'\n'}
                    You're loosing sense - I'm hell-sent for you{'\n'}
                    Speak of the devil{'\n'}
                    You can't walk away your soul belongs to me{'\n'}
                    Speak of the devil{'\n'}
                    {'\n'}
                    There's a one way ride for you my boy{'\n'}
                    And turning back is not an option{'\n'}
                    Say goodbye to all the ones you love{'\n'}
                    The midnight train is already in motion{'\n'}
                    {'\n'}
                    Purgatory waits for you my friend{'\n'}
                    And there's a seat with your name on it{'\n'}
                    You're passing through the gates of hell{'\n'}
                    {'\n'}
                    Speak of the devil{'\n'}
                    You're loosing sense - I'm hell-sent for you{'\n'}
                    Speak of the devil{'\n'}
                    You can't walk away your soul belongs to me{'\n'}
                    Speak of the devil{'\n'}
                    {'\n'}
                    You will be punished for your sins{'\n'}
                    These sins been done by you{'\n'}
                    Your sands of time are running fast{'\n'}
                    Your flesh and blood will turn to dust{'\n'}
                    {'\n'}
                    Speak of the devil{'\n'}
                    You're loosing sense - I'm hell-sent for you{'\n'}
                    Speak of the devil{'\n'}
                    You can't walk away your soul belongs to me{'\n'}
                    Speak of the devil{'\n'}
                    You've lost your sense - now hell awaits for you{'\n'}
                    Speak of the devil{'\n'}
                    In vain you pray, your soul is here to stay{'\n'}
                    Speak of the devil
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>7. Planet Metal (4:36)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Far into the universe a million light-years away{'\n'}
                    There is a secret planet{'\n'}
                    There're some unknown forms of life{'\n'}
                    {'\n'}
                    This planet is a paradise for every Gabian metalhead{'\n'}
                    I've checked it on my monitor{'\n'}
                    My antennas never lie{'\n'}
                    {'\n'}
                    All set... All the crew must take their places{'\n'}
                    Countdown... we're leaving planet Gabe{'\n'}
                    (3,2,1, ignition){'\n'}
                    {'\n'}
                    We're all leaving for p.m.{'\n'}
                    Long trip beyond the stars{'\n'}
                    Excitement of a new world's straight ahead{'\n'}
                    Ten seconds after Mars{'\n'}
                    Just set the speed of light{'\n'}
                    We've found ourselves a place like paradise{'\n'}
                    {'\n'}
                    I hope we are the only ones{'\n'}
                    Who made this trip from outerspace{'\n'}
                    But damn... if there are metalheads{'\n'}
                    This is the meeting place{'\n'}
                    {'\n'}
                    I just can’t hold my 20 feet{'\n'}
                    I'm anxious make it faster please{'\n'}
                    Stop it or I'll radiate you{'\n'}
                    You won't leave a trace{'\n'}
                    {'\n'}
                    Touch down... behave yourselfs we're out of the spaceship{'\n'}
                    Hot flush... at last we're down on earth{'\n'}
                    {'\n'}
                    We're all leaving for p.m.{'\n'}
                    Long trip beyond the stars{'\n'}
                    Excitement of a new world's straight ahead{'\n'}
                    Ten seconds after Mars{'\n'}
                    Just set the speed of light{'\n'}
                    We've found ourselves a place like paradise{'\n'}
                    {'\n'}
                    (One small step from a Gabian{'\n'}
                    One huge step for gabianity){'\n'}
                    {'\n'}
                    Oh dear!!! The mean Galacto is right behind us{'\n'}
                    Oh shit!!! We're crushing planet Gabe{'\n'}
                    (aaaahhhhh!!!){'\n'}
                    {'\n'}
                    We're all leaving for p.m.{'\n'}
                    Long trip beyond the stars{'\n'}
                    Excitement of a new world's straight ahead{'\n'}
                    Ten seconds after Mars{'\n'}
                    Just set the speed of light{'\n'}
                    We've found ourselves a place like paradise{'\n'}
                    {'\n'}
                    (Damn spaceship, it's always broken)
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>8. Chasing The Wind (4:16)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Every time I look back into my life{'\n'}
                    I feel my pain growing in my heart{'\n'}
                    Nobody has ever cared for me{'\n'}
                    {'\n'}
                    But I know this is my chance{'\n'}
                    I'm spreading my wings{'\n'}
                    To reach for the sky{'\n'}
                    {'\n'}
                    I am chasing the wind{'\n'}
                    Seems like it blows{'\n'}
                    Where my heart beats{'\n'}
                    Riding on the clouds{'\n'}
                    I'm falling back into my life{'\n'}
                    I am chasing the wind{'\n'}
                    Seems like it blows{'\n'}
                    Where my heart beats{'\n'}
                    Every breath I take I feel I'm free{'\n'}
                    Chasing the wind{'\n'}
                    {'\n'}
                    I won't regret for things{'\n'}
                    I'll do tomorrow{'\n'}
                    Only those I missed the chance to do{'\n'}
                    The day will come to justify my anger{'\n'}
                    {'\n'}
                    There's a way I seize the day{'\n'}
                    This is my right to reach for the sky{'\n'}
                    {'\n'}
                    I am chasing the wind{'\n'}
                    Seems like it blows{'\n'}
                    Where my heart beats{'\n'}
                    Riding on the clouds{'\n'}
                    I'm falling back into my life{'\n'}
                    I am chasing the wind{'\n'}
                    Seems like it blows{'\n'}
                    Where my heart beats{'\n'}
                    Every breath I take I feel I'm free{'\n'}
                    Chasing the wind{'\n'}
                    {'\n'}
                    I seize the day this is my right{'\n'}
                    I'm leaving my worries behind{'\n'}
                    This is my chance - I'm spreading my wings{'\n'}
                    To reach for the sky{'\n'}
                    {'\n'}
                    I am chasing the wind{'\n'}
                    Seems like it blows{'\n'}
                    Where my heart beats{'\n'}
                    Riding on the clouds{'\n'}
                    I'm falling back into my life{'\n'}
                    I am chasing the wind{'\n'}
                    Seems like it blows{'\n'}
                    Where my heart beats{'\n'}
                    Every breath I take I feel I'm free{'\n'}
                    Chasing the wind
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>9. Fantasmagoria (12:24)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Holy spirit{'\n'}
                    Sometimes it's hard{'\n'}
                    Much harder than you though it will be{'\n'}
                    Or even worse{'\n'}
                    Your heart might not take it{'\n'}
                    {'\n'}
                    But be the fearless one{'\n'}
                    You must begin your quest{'\n'}
                    You are the center of all life{'\n'}
                    Our hope rests in your hands{'\n'}
                    {'\n'}
                    The chosen{'\n'}
                    I'm gazing the stars{'\n'}
                    Tonight they'll spread their light upon me{'\n'}
                    And they'll fill my heart{'\n'}
                    With wisdom bravery and power{'\n'}
                    {'\n'}
                    I am the chosen one{'\n'}
                    King of all humankind{'\n'}
                    I'll live an everlasting life{'\n'}
                    {'\n'}
                    Holy spirit & the chosen{'\n'}
                    Fulfil the prophecy{'\n'}
                    And find the falling star{'\n'}
                    The signs reveal the path of life{'\n'}
                    Prevent the end of time{'\n'}
                    {'\n'}
                    The chosen{'\n'}
                    Mirror open up the gate{'\n'}
                    The portal to that{'\n'}
                    Strange and unknown world{'\n'}
                    Mirror you reflect my fate{'\n'}
                    Break the rules don't be a{'\n'}
                    Time-waster in my quest{'\n'}
                    {'\n'}
                    Wicked soul{'\n'}
                    Lust lust{'\n'}
                    The chosen{'\n'}
                    Lust for glory{'\n'}
                    Wicked soul{'\n'}
                    Lust lust{'\n'}
                    The chosen{'\n'}
                    For everlasting life{'\n'}
                    Wicked soul{'\n'}
                    I have no time for your lies{'\n'}
                    Greed greed{'\n'}
                    Inner voice{'\n'}
                    Always there's a price{'\n'}
                    That you have to pay{'\n'}
                    {'\n'}
                    Humankind{'\n'}
                    Accept the consequences{'\n'}
                    Inner voice{'\n'}
                    But you better mark these words{'\n'}
                    {'\n'}
                    Inner voice{'\n'}
                    Rest for a while{'\n'}
                    Your mind must be strong{'\n'}
                    Humankind{'\n'}
                    Must be strong{'\n'}
                    Inner voice{'\n'}
                    Gates full of fire{'\n'}
                    Riddles unsolved{'\n'}
                    You'll find on your way{'\n'}
                    {'\n'}
                    Humankind{'\n'}
                    Fantasmagoria{'\n'}
                    A path of blood and fire{'\n'}
                    That leads your soul down to the demon's nest{'\n'}
                    Fantasmagoria{'\n'}
                    Reveal the master-spell{'\n'}
                    Unleash the Armageddon at the gates of hell{'\n'}
                    {'\n'}
                    The chosen{'\n'}
                    Riddles riddles on my way{'\n'}
                    Must be solved to break the{'\n'}
                    Wall and meet my fate{'\n'}
                    Time to face the wicked soul{'\n'}
                    And destroy his judgement{'\n'}
                    I must have the upper hand{'\n'}
                    {'\n'}
                    Wicked soul{'\n'}
                    Dark - light?{'\n'}
                    Inner voice{'\n'}
                    Choose your answer{'\n'}
                    Wicked soul{'\n'}
                    Wrong - right?{'\n'}
                    The chosen{'\n'}
                    The reign of hell will fall{'\n'}
                    Wicked soul{'\n'}
                    Lucifer is now aware of...{'\n'}
                    You!{'\n'}
                    Inner voice{'\n'}
                    The hell spawn knows you're there{'\n'}
                    He reads your mind, beware{'\n'}
                    {'\n'}
                    Humankind{'\n'}
                    The wrath of humankind{'\n'}
                    Inner voice{'\n'}
                    And he's coming at your way!{'\n'}
                    {'\n'}
                    Humankind{'\n'}
                    Have no fear{'\n'}
                    We will all be there by your side{'\n'}
                    In your mind{'\n'}
                    Enlightening your path{'\n'}
                    Have no fear{'\n'}
                    Don't give up{'\n'}
                    {'\n'}
                    Inner voice{'\n'}
                    The nameless awaiting{'\n'}
                    In the spirit world{'\n'}
                    Humankind{'\n'}
                    Spirit world{'\n'}
                    Inner voice{'\n'}
                    The troops of evil{'\n'}
                    The forces of doom{'\n'}
                    You'll find on your way{'\n'}
                    {'\n'}
                    Fantasmagoria{'\n'}
                    {'\n'}
                    Hell spawn{'\n'}
                    You have read the signs{'\n'}
                    But you have lost their meaning{'\n'}
                    You're a pawn into this game{'\n'}
                    The game of hell... you stupid little man{'\n'}
                    {'\n'}
                    The chosen{'\n'}
                    The reign of hell will fall{'\n'}
                    You can trick me with your lies{'\n'}
                    But you can't possess my soul{'\n'}
                    {'\n'}
                    The chosen{'\n'}
                    I hear the voices whispering{'\n'}
                    What is a king to do{'\n'}
                    I'm meant to fight against all odds{'\n'}
                    But I'm so afraid to die{'\n'}
                    {'\n'}
                    Holly spirit{'\n'}
                    I'll wash away your fear{'\n'}
                    I'll give you my own heart{'\n'}
                    My wings to fly{'\n'}
                    My will to live{'\n'}
                    I am standing by your side{'\n'}
                    {'\n'}
                    Hell spawn{'\n'}
                    It's time to run{'\n'}
                    It's time to hide{'\n'}
                    That's how it goes{'\n'}
                    You're weak and so powerless{'\n'}
                    {'\n'}
                    The chosen{'\n'}
                    It's time to scream{'\n'}
                    Time to die{'\n'}
                    Time to kiss your life goodbye{'\n'}
                    The souls you've sucked{'\n'}
                    Will through away your corps{'\n'}
                    Straight to hell{'\n'}
                    {'\n'}
                    Humankind{'\n'}
                    Have no fear{'\n'}
                    The falling star now rises{'\n'}
                    Once again{'\n'}
                    Up to the endless sky{'\n'}
                    Have no fear{'\n'}
                    Now we're free{'\n'}
                    {'\n'}
                    Inner voice{'\n'}
                    The forces of evil{'\n'}
                    Are now drowned in hell{'\n'}
                    Humankind{'\n'}
                    Drowned in hell{'\n'}
                    Inner voice{'\n'}
                    The kingdom of heaven{'\n'}
                    Waits up for the king{'\n'}
                    The eternal peace{'\n'}
                    {'\n'}
                    Humankind{'\n'}
                    Fantasmagoria{'\n'}
                    A path of blood and fire{'\n'}
                    That leads your soul down to the demon's nest{'\n'}
                    Fantasmagoria{'\n'}
                    Reveal the master-spell{'\n'}
                    Unleash the Armageddon at the gates of hell{'\n'}
                    {'\n'}
                    The chosen{'\n'}
                    We are kings in the kingdom of heaven{'\n'}
                    Forever, forever... for everlasting live{'\n'}
                    {'\n'}
                    Fantasmagoria
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>10. Holding Out For A Hero (Bonnie Tyler cover) (4:17)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Where have all the good men gone{'\n'}
                    And where are all the gods?{'\n'}
                    Where's the street-wise Hercules{'\n'}
                    To fight the rising odds?{'\n'}
                    Isn't there a white knight upon a fiery steed?{'\n'}
                    Late at night I toss and turn and dream{'\n'}
                    of what I need{'\n'}
                    {'\n'}
                    I need a hero{'\n'}
                    I'm holding out for a hero 'til the end of the night{'\n'}
                    He's got to be strong{'\n'}
                    And he's got to be fast{'\n'}
                    And he's got to be fresh from the fight{'\n'}
                    I need a hero{'\n'}
                    I'm holding out for a hero 'til the morning light{'\n'}
                    He's got to be sure{'\n'}
                    And it's got to be soon{'\n'}
                    And he's got to be larger than life{'\n'}
                    {'\n'}
                    Somewhere after midnight{'\n'}
                    In my wildest fantasy{'\n'}
                    Somewhere just beyond my reach{'\n'}
                    There's someone reaching back for me{'\n'}
                    Racing on the thunder end rising with the heat{'\n'}
                    It's gonna take a superman to sweep me off my feet{'\n'}
                    {'\n'}
                    I need a hero{'\n'}
                    I'm holding out for a hero 'til the end of the night{'\n'}
                    He's got to be strong{'\n'}
                    And he's got to be fast{'\n'}
                    And he's got to be fresh from the fight{'\n'}
                    I need a hero{'\n'}
                    I'm holding out for a hero 'til the morning light{'\n'}
                    He's got to be sure{'\n'}
                    And it's got to be soon{'\n'}
                    And he's got to be larger than life{'\n'}
                    {'\n'}
                    Up where the mountains meet the heavens above{'\n'}
                    Out where the lightning splits the sea{'\n'}
                    I could swear that there's someone somewhere{'\n'}
                    Watching me{'\n'}
                    {'\n'}
                    Through the wind end the chill and the rain{'\n'}
                    And the storm and the flood{'\n'}
                    I can feel his approach{'\n'}
                    Like the fire in my blood
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card.Content>
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="ESCAPE FROM TWILIGHT (2007)" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*1200)/1200 }} source={require('../assets/escape_from_twilight.jpg')} />
          <Card.Content style={myCss.cardContent}>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>1. Sunrise (1:29)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    (instrumental)
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>2. Scream Out Loud (5:08)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    We are the future{'\n'}
                    The youth crying with tears in their eyes{'\n'}
                    We're screaming for freedom{'\n'}
                    No more promises they're all lies{'\n'}
                    {'\n'}
                    Help us find our way{'\n'}
                    {'\n'}
                    The battles of life{'\n'}
                    Are becoming straighter every day{'\n'}
                    The walls of our nations{'\n'}
                    Will fall in a minute today{'\n'}
                    {'\n'}
                    Help us find our way{'\n'}
                    Now it's time to say...{'\n'}
                    {'\n'}
                    That we're here to scream out loud{'\n'}
                    We don't need to ask for mercy{'\n'}
                    Feel the anger, feel the power, feel the pain{'\n'}
                    We will fight until the end{'\n'}
                    We will earn the right for living{'\n'}
                    In the future{'\n'}
                    We'll be breathing free again{'\n'}
                    {'\n'}
                    So try to fit yourself into a paradise{'\n'}
                    {'\n'}
                    We're trying to pull out{'\n'}
                    The venom that tears off our spine{'\n'}
                    We don't wanna put{'\n'}
                    Ourselves into a stream{'\n'}
                    That's no crime{'\n'}
                    {'\n'}
                    Never hesitate{'\n'}
                    Now it's not too late{'\n'}
                    {'\n'}
                    'Cause we're here to scream out loud{'\n'}
                    We don't need to ask for mercy{'\n'}
                    {'\n'}
                    Feel the anger, feel the power, feel the pain{'\n'}
                    We will fight until the end{'\n'}
                    We will earn the right for living{'\n'}
                    In the future{'\n'}
                    We'll be breathing free again{'\n'}
                    {'\n'}
                    So try to fit yourself in your hell{'\n'}
                    {'\n'}
                    We will fight until the end{'\n'}
                    We will earn the right for living{'\n'}
                    In the future{'\n'}
                    We'll be breathing free again
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>3. Sword of Light (6:54)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    For many years I travel{'\n'}
                    to a land that 's miles away{'\n'}
                    And now the time has come{'\n'}
                    To find a place for me to stay{'\n'}
                    But before that time arrive{'\n'}
                    I have many things to say{'\n'}
                    Come along and stay behind{'\n'}
                    We are traveling today...{'\n'}
                    {'\n'}
                    Many miles away{'\n'}
                    From the land we step today{'\n'}
                    There 's a place{'\n'}
                    Beyond your dreams{'\n'}
                    And far away{'\n'}
                    There my father was the ruler{'\n'}
                    Faced his fate because he signed{'\n'}
                    For the power{'\n'}
                    That the world had never seen{'\n'}
                    {'\n'}
                    A myth comes from the past{'\n'}
                    Says that death is coming fast{'\n'}
                    If you use the blade{'\n'}
                    But that is not the last{'\n'}
                    So he used the sword of dragons{'\n'}
                    Took the power in his hands{'\n'}
                    Made himself a king{'\n'}
                    -You 're waiting for the end?{'\n'}
                    {'\n'}
                    Filled with dragon tears...{'\n'}
                    There 's the lake of fears...{'\n'}
                    {'\n'}
                    The guardians of the lake{'\n'}
                    Show the path you have to take{'\n'}
                    The night is long, you have to stay awake{'\n'}
                    Don't you start the fight{'\n'}
                    You will break the curse tonight{'\n'}
                    To the lake you 'll throw the sword...{'\n'}
                    Of the light{'\n'}
                    {'\n'}
                    I have seen the flame{'\n'}
                    Coming hot out of his eyes{'\n'}
                    As he told me what to do{'\n'}
                    Before he die{'\n'}
                    Now I say the same thing to you{'\n'}
                    You must hear me my good friend{'\n'}
                    'Cause I 'm tired and tonight{'\n'}
                    Our trip will end{'\n'}
                    {'\n'}
                    Filled with dragon tears...{'\n'}
                    There 's the lake of fears...{'\n'}
                    {'\n'}
                    The guardians of the lake{'\n'}
                    Show the path you have to take{'\n'}
                    The night is long, you have to stay awake{'\n'}
                    Don't you start the fight{'\n'}
                    You will break the curse tonight{'\n'}
                    To the lake you 'll throw the sword...{'\n'}
                    Of the light{'\n'}
                    {'\n'}
                    Final words of mine{'\n'}
                    Cause my life runs out of time{'\n'}
                    You must take the sword{'\n'}
                    But don't you cross the line{'\n'}
                    As my father crossed before you{'\n'}
                    And he carried the last curse{'\n'}
                    You must throw it or else{'\n'}
                    You will face the worst{'\n'}
                    {'\n'}
                    Filled with dragon tears...{'\n'}
                    There 's the lake of fears...{'\n'}
                    {'\n'}
                    The guardians of the lake{'\n'}
                    Show the path you have to take{'\n'}
                    The night is long, you have to stay awake{'\n'}
                    Don't you start the fight{'\n'}
                    You will break the curse tonight{'\n'}
                    To the lake you 'll throw the sword...{'\n'}
                    Of the light
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>4. High In The Sky (6:43)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Lost,{'\n'}
                    Into the night, under the moonlight{'\n'}
                    Darkness falling{'\n'}
                    You, you're searching the path{'\n'}
                    That leads to the light{'\n'}
                    {'\n'}
                    I'll{'\n'}
                    Be there by your side{'\n'}
                    To show you the right way{'\n'}
                    Don't deny it{'\n'}
                    Raise your hands watch the sky{'\n'}
                    I am passing by{'\n'}
                    {'\n'}
                    Aaa?{'\n'}
                    {'\n'}
                    You know I am the one{'\n'}
                    That I always spread the rays of fire{'\n'}
                    You I am the one{'\n'}
                    To show you how{'\n'}
                    {'\n'}
                    Try{'\n'}
                    And you'll find the way{'\n'}
                    That will lead you to the{'\n'}
                    Tomb of glory{'\n'}
                    Don't, do not give it up{'\n'}
                    There's no turning back{'\n'}
                    {'\n'}
                    Hear{'\n'}
                    The wind's passing by{'\n'}
                    Just listen the whisper{'\n'}
                    Someone's calling{'\n'}
                    Oh! The savior will come{'\n'}
                    You know I'm the one{'\n'}
                    {'\n'}
                    That I'll always walk{'\n'}
                    By your side towards{'\n'}
                    The daily light yeah{'\n'}
                    Raise your head and watch me{'\n'}
                    I'll be there{'\n'}
                    {'\n'}
                    ...In the sky{'\n'}
                    I am flying so high{'\n'}
                    I will always spread fire{'\n'}
                    For eternal life{'\n'}
                    ...In the sky{'\n'}
                    You all know I won't die{'\n'}
                    And you 'll see my rays falling{'\n'}
                    Crossing the night{'\n'}
                    ...In the sky{'\n'}
                    {'\n'}
                    As I hear the wind{'\n'}
                    That blows right by my ear{'\n'}
                    I know there 's no one just like me{'\n'}
                    'Cause I feel alive{'\n'}
                    As I rise above the mountains{'\n'}
                    And I fly high in the sky{'\n'}
                    {'\n'}
                    You know I am the one{'\n'}
                    That I always spread the rays of fire{'\n'}
                    You I am the one{'\n'}
                    To show you how{'\n'}
                    That I'll always walk{'\n'}
                    By your side towards{'\n'}
                    The daily light yeah{'\n'}
                    Raise your head and watch me{'\n'}
                    I'll be there{'\n'}
                    {'\n'}
                    ...In the sky{'\n'}
                    I am flying so high{'\n'}
                    I will always spread fire{'\n'}
                    For eternal life{'\n'}
                    ...In the sky{'\n'}
                    You all know I won't die{'\n'}
                    And you 'll see my rays falling{'\n'}
                    Crossing the night{'\n'}
                    ...In the sky
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>5. Eyes of Prophecy (4:51)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Long,{'\n'}
                    Is the path that takes you in{'\n'}
                    To meet your secret dream{'\n'}
                    That you 're keeping in your fantasy{'\n'}
                    {'\n'}
                    Sleep,{'\n'}
                    In the night on a magical tree{'\n'}
                    Close your eyes and you will see{'\n'}
                    That your dream will become reality{'\n'}
                    {'\n'}
                    Search,{'\n'}
                    In your unborn seven dreams{'\n'}
                    To find dragons and kings{'\n'}
                    The empty nights of the oldest world{'\n'}
                    {'\n'}
                    Sleep,{'\n'}
                    In a landscape drawn by the sun{'\n'}
                    Your dream it shall be done{'\n'}
                    'Cause you have the eyes of prophecy{'\n'}
                    {'\n'}
                    I see the horizon,{'\n'}
                    I see the stars of the sky{'\n'}
                    I see birds around the sun,{'\n'}
                    I wish I could fly{'\n'}
                    To see the paradise,{'\n'}
                    where there 's no cry{'\n'}
                    But this is only a dream,{'\n'}
                    I wish I could die?{'\n'}
                    {'\n'}
                    The eyes of prophecy{'\n'}
                    {'\n'}
                    The eyes of prophecy
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>6. Emerald Sun (5:56)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    The false prophet spoke{'\n'}
                    Of an uncertain future{'\n'}
                    But our fate guards these simple words{'\n'}
                    As the six rise their fists upon the sky{'\n'}
                    The first hit will be sealed in history
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>7. Not Alone (5:18)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Now that my time has come to go{'\n'}
                    Please stand by my side{'\n'}
                    Hold the tears and say goodbye{'\n'}
                    Fill my heart with hopeful dreams{'\n'}
                    Let them be my guide to where I go{'\n'}
                    {'\n'}
                    Touch your heart and close your eyes{'\n'}
                    I'll always be there, I will be the shiny star{'\n'}
                    The wind in your hair, whisper on!{'\n'}
                    {'\n'}
                    You're not alone...{'\n'}
                    {'\n'}
                    I must walk my path today{'\n'}
                    To the landscape of light{'\n'}
                    Though my thoughts will hold you tight{'\n'}
                    Now there's nothing left to say{'\n'}
                    Please wish me fair way{'\n'}
                    I'm not coming back{'\n'}
                    {'\n'}
                    Touch your heart and close your eyes{'\n'}
                    I'll always be there, I will be the shiny star{'\n'}
                    The wind in your hair, whisper on!{'\n'}
                    {'\n'}
                    You're not alone...{'\n'}
                    {'\n'}
                    I'll be there when moonlight fills the night{'\n'}
                    When crystal rain will fall down from the sky{'\n'}
                    And my hands will be wiping off your tears{'\n'}
                    I will be there{'\n'}
                    {'\n'}
                    Touch your heart and close your eyes{'\n'}
                    I'll always be there, I will be the shiny star{'\n'}
                    The wind in your hair, whisper on!{'\n'}
                    {'\n'}
                    Touch your heart and close your eyes{'\n'}
                    I'll always be there, I will be the shiny star{'\n'}
                    The wind in your hair, whisper on!{'\n'}
                    {'\n'}
                    You're not alone...{'\n'}
                    {'\n'}
                    Say you're not alone{'\n'}
                    {'\n'}
                    ...Whisper on!{'\n'}
                    {'\n'}
                    You're not alone...
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>8. Escape (5:38)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    Thousands and thousands{'\n'}
                    Of moments will pass{'\n'}
                    And your life will come to an end{'\n'}
                    You'll be wondering{'\n'}
                    How many things you have lost{'\n'}
                    So stand up and live on again{'\n'}
                    {'\n'}
                    See your life from another point of view{'\n'}
                    Walk on with your head up high{'\n'}
                    Don't you think what you're giving{'\n'}
                    But what you can get{'\n'}
                    And reach for the glory of life{'\n'}
                    {'\n'}
                    For once in your life make some noise{'\n'}
                    Do something, try, help yourself to survive{'\n'}
                    For once in your life raise your voice{'\n'}
                    {'\n'}
                    I am alive{'\n'}
                    {'\n'}
                    Escape from twilight{'\n'}
                    Escape...rise from the ashes{'\n'}
                    Of your burning soul{'\n'}
                    Escape from twilight{'\n'}
                    For once in your life try to escape{'\n'}
                    {'\n'}
                    Live for the moment{'\n'}
                    Don't you waste your time{'\n'}
                    And don't say that nothing's for free{'\n'}
                    Life is full of surprises{'\n'}
                    You'll see what I mean{'\n'}
                    Open your wings in the sky{'\n'}
                    {'\n'}
                    For once in your life make some noise...{'\n'}
                    Do something, try to escape to survive{'\n'}
                    For once in your life raise your voice{'\n'}
                    {'\n'}
                    I am alive{'\n'}
                    {'\n'}
                    Escape from twilight{'\n'}
                    Escape from twilight{'\n'}
                    Rise from the ashes{'\n'}
                    Of your burning soul{'\n'}
                    Escape from twilight{'\n'}
                    For once in your life try to escape{'\n'}
                    Escape{'\n'}
                    {'\n'}
                    ...Twilight...{'\n'}
                    ...Twilight...{'\n'}
                    ...Twilight...{'\n'}
                    {'\n'}
                    Life...{'\n'}
                    Marches on...{'\n'}
                    The years will fly...{'\n'}
                    Don't waste your time...{'\n'}
                    Live before it's too late...{'\n'}
                    {'\n'}
                    Escape from twilight{'\n'}
                    Escape...{'\n'}
                    Rise from the ashes of your burning soul{'\n'}
                    Escape from twilight{'\n'}
                    {'\n'}
                    For once in your life try to escape{'\n'}
                    ...Escape
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>9. And the Story Begins (4:48)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    The flame of the candle is fadding away{'\n'}
                    the moon gives its place to the new rising day{'\n'}
                    my scrollpaper's writting my gratefull dreams{'\n'}
                    so now my quest starts and the story begins{'\n'}
                    {'\n'}
                    i'm ridding the oseans i'm reaching the hills{'\n'}
                    and crossing the forests fron west to the east{'\n'}
                    i'm singing my stories were ever i go{'\n'}
                    my biggest andventures the hole world to know{'\n'}
                    {'\n'}
                    but as the day is fadding and moonlight appears{'\n'}
                    i'm lighting the candels again{'\n'}
                    i stand in the forest rewritting my dreams{'\n'}
                    until my next journey begins
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={myCss.songLyricsAccordion}>
              <AccordionSummary style={myCss.songLyricsAccordionSummary} expandIcon={<ExpandMoreIcon />} >
                <Typography>10. Heavy Metal (04:44)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Text style={myCss.songLyricsText}>
                    From the time that you appeared{'\n'}
                    And for over thirty years{'\n'}
                    We've been riding the sound with guitars{'\n'}
                    {'\n'}
                    From the time we've touched the strings{'\n'}
                    And felt the lightning coming out{'\n'}
                    There's nothing but metal to think{'\n'}
                    {'\n'}
                    There's power in us all{'\n'}
                    A lot of strength inside us{'\n'}
                    Feel it in our hearts{'\n'}
                    And our souls are still devoted to{'\n'}
                    {'\n'}
                    Heavy metal{'\n'}
                    Once you will hear it{'\n'}
                    There's no turning back{'\n'}
                    Heavy metal{'\n'}
                    This is a gift that God gave to us{'\n'}
                    Heavy metal{'\n'}
                    Once you will hear it{'\n'}
                    There's no turning back{'\n'}
                    Heavy metal{'\n'}
                    This is a gift that God gave to us{'\n'}
                    {'\n'}
                    And it will sound for eternity{'\n'}
                    {'\n'}
                    All the hard arpeggio solos{'\n'}
                    And the power on the stage{'\n'}
                    Are like a whiplash{'\n'}
                    Thats whipping our minds{'\n'}
                    {'\n'}
                    All those years live and loud{'\n'}
                    With all the metal-heads on our side{'\n'}
                    There's nothing to fear at all{'\n'}
                    {'\n'}
                    There's power in us all{'\n'}
                    A lot of strength inside us{'\n'}
                    Feel it in our hearts{'\n'}
                    And our souls are still devoted to{'\n'}
                    {'\n'}
                    Heavy metal{'\n'}
                    Once you will hear it{'\n'}
                    There's no turning back{'\n'}
                    Heavy metal{'\n'}
                    This is a gift that God gave to us{'\n'}
                    Heavy metal{'\n'}
                    Once you will hear it{'\n'}
                    There's no turning back{'\n'}
                    Heavy metal{'\n'}
                    This is a gift that God gave to us{'\n'} 
                    {'\n'}
                    And it will sound for eternity{'\n'}
                    {'\n'}
                    There's power in us all{'\n'}
                    A lot of strength inside us{'\n'}
                    Feel it in our hearts{'\n'}
                    And our souls are still devoted to{'\n'}
                    {'\n'}
                    Heavy metal{'\n'}
                    Once you will hear it{'\n'}
                    There's no turning back{'\n'}
                    Heavy metal{'\n'}
                    This is a gift that God gave to us{'\n'}
                    Heavy metal{'\n'}
                    Once you will hear it{'\n'}
                    There's no turning back{'\n'}
                    Heavy metal{'\n'}
                    This is a gift that God gave to us{'\n'}
                    {'\n'}
                    And it will sound for eternity
                  </Text>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card.Content>
        </Card>
      </View>
      <Footer/>
    </View>
  );
};

export default DiscographyScreen;