import { View, Text } from "react-native";
import { Divider } from "react-native-paper";
import myCss from "../css/myCss";

const Footer = () => {
     return (
         <View style={myCss.footer}>
            <Divider style={myCss.divider}/>
            <Text style={myCss.footerText}>© 2024 - Emerald Sun - Website Designed & Developed by Nick Kaklanis</Text>
         </View>
     );
};
  
export default Footer; 