import { View, Text } from 'react-native';
import { Card, Divider } from 'react-native-paper';
import myCss from '../css/myCss';
import Header from './Header';
import Footer from './Footer';
import GenericConstManager from '../managers/GenericConstManager';


const BandScreen = ({ navigation }) => {
  return (
    <View style={myCss.container}>
      <Header/>
      <View style={myCss.mainApp}>
        <View style={{ paddingLeft: 50, paddingRight: 50 }}>
          <Text style={myCss.text}>Formed in 1998, in Thessaloniki, Greece, EMERALD SUN has performed live numerous times becoming an extremely sought-after live act. Past performances included their participation at festivals such as <Text style={myCss.textBoldRed}>March Metal Day</Text>, <Text style={myCss.textBoldRed}>Metal Healing</Text>, <Text style={myCss.textBoldRed}>Maximum Rock Fest</Text> and others. The band’s excellent two demos "Sword Of Light" and "High In The Sky", released in 2003 and 2004 led to the release of their first full length album in 2007 (“Escape from Twilight”) which was later released in Japan with exclusive bonus material.</Text>
          <Text style={myCss.text}>After several live concerts with bands like <Text style={myCss.textBoldRed}>Manowar</Text>, <Text style={myCss.textBoldRed}>Rhapsody of Fire</Text>, <Text style={myCss.textBoldRed}>Edguy</Text>, <Text style={myCss.textBoldRed}>Grim Reaper</Text>, <Text style={myCss.textBoldRed}>Omen</Text>, <Text style={myCss.textBoldRed}>Cynic</Text> and a live appearance at the TV show Jammin in the state channel ET3, the band entered the studio and recorded the first official album with worldwide contribution in 2007 ALBUM "ESCAPE FROM TWILIGHT".</Text>
          <Text style={myCss.text}>In 2009 Stelios Tsakiridis joined the band, replacing Jimmy on vocals and a week later the recordings for the new album "Regeneration" started, which was released worldwide in February 2011 by „Pitch Black Records“</Text>
          <Text style={myCss.text}><Text style={myCss.textBoldWhite}>2011 "REGENERATION" album: </Text>"Regeneration" includes ten new tracks of utterly brilliant melodic heavy/power metal! It was recorded, mixed and mastered at <Text style={myCss.textBoldRed}>Maranis Studios in Backnang</Text>, Germany by Vagelis Maranis (ex-Sanvoisen) who also lends his voice on the 12-minute epic “Fantasmagoria.” The CD version of “Regeneration” includes as a bonus track a killer cover version of 80’s pop classic “Holding out for a Hero” and will be available worldwide through <Text style={myCss.textBoldRed}>Pitch Black Records</Text> and <Text style={myCss.textBoldRed}>Twilight-Distribution</Text>. The album is also be available for digital download through several mp3 stores.</Text>
          <Text style={myCss.text}><Text style={myCss.textBoldWhite}>2015 "METAL DOME” album: </Text>Through <Text style={myCss.textBoldRed}>Fastball Music/Soulfood</Text> on 22th May 2015. It is the band’s third album and contains 12 new songs including a very special duet with <Text style={myCss.textBoldRed}>Liv Kristine (Leaves Eyes, Theatre Of Tragedy)</Text>. With “METAL DOME” the six Greeks present an impressive come back and celebrate a fantastic firework of melodic anthems. The album can easily compete with its predecessors and even sets a new peak for the band. An extensive support-/club tour and festival shows are in preparation.</Text>
          <Text style={myCss.text}><Text style={myCss.textBoldWhite}>2018 "UNDER THE CURSE OF SILENCE" album: </Text>We're happy to announce the release of the new EMERALD SUN album through <Text style={myCss.textBoldRed}>Fastball Music/SOULFOOD</Text> on April, 13th. On "Under The Curse Of Silence" the band goes at it full steam ahead. Properly produced by current RAGE guitarist and studio expert <Text style={myCss.textBoldRed}>Marcos Rodriguez</Text>, the eleven album tracks blast from the loudspeakers like there is no tomorrow. Singer Stelios "Theo" Tsakiridis knows how to command the full range of a classic metal vocalist demonstrating his skills not only in up-tempo hits like "Kill Or Be Killed" or "Weakness And Shame", but also with slower tunes like the wonderful rock ballad "Journey Of Life" or the bonus track "La Fuerza Del Ser" sung in Spanish. A special highlight is the crashy "Blast" featuring <Text style={myCss.textBoldRed}>RAGE frontman Peavy Wagner</Text> for which a video clip will be released when the album comes out. Emerald Sun will play several live shows this year giving proof of their driving metal expertise and stage experience. Among others, they are going to fire up their fans at the <Text style={myCss.textBoldRed}>Metal Diver Festival</Text> in March together with <Text style={myCss.textBoldRed}>RAGE</Text> and <Text style={myCss.textBoldRed}>Firewind</Text>. Emerald Sun get the idea of epic power metal in all its facets and demonstrate again on "Under The Curse Of Silence" their significance in the international and especially Greek metal scene.</Text>
        </View>
        <Divider style={[myCss.divider, { marginTop: 15, marginBottom: 10 }]}/>
        <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Band Members</Text>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="Stelios Theo Tsakiridis - Vocals" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*960)/640 }} source={require('../assets/image5.jpg')} />
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="Teo Savage - Guitar" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*4752)/3168 }} source={require('../assets/image24.jpg')} />
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="Pavlos Georgiadis - Guitar" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*587)/718 }} source={require('../assets/image14.jpg')} />
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="Fotis Sheriff Toumanides - Bass" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*640)/960 }} source={require('../assets/image6.jpg')} />
        </Card>
        <Card style={myCss.bandMemberCard}>
          <Card.Title titleStyle={myCss.bandMemberCardTitle} title="Nick Kaklanis - Drums" />
          <Card.Cover style={{ width: GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH, height: (GenericConstManager.getInstance().BAND_MEMBER_CARD_WIDTH*3168)/4752 }} source={require('../assets/image27.jpg')} />
        </Card>
      </View>
      <Footer/>
    </View>
  );
};

export default BandScreen;