import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import GenericConstManager from '../managers/GenericConstManager';
import { View, Text, Image, TouchableOpacity, Dimensions } from 'react-native';
import myCss from '../css/myCss';
import Header from './Header';
import Footer from './Footer';

const screenDimensions = Dimensions.get('window');

const HomeScreen = () => {
  return (
    <View style={myCss.container}>
      <Header/>
      <Image
          source={require('../assets/EmeraldSun_Principal.png')}
          style={{ width: screenDimensions.width, height: screenDimensions.width*(2115/3264), maxWidth: 1200, maxHeight: 1200*(2115/3264) }} 
      />
      <Footer/>
    </View>
  );
};

export default HomeScreen;